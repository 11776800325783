export const CONTINENTS = {
    'AF': {
        'name': 'Africa',
        'countries': [
            'AO',
            'BF',
            'BI',
            'BJ',
            'BW',
            'CD',
            'CF',
            'CG',
            'CI',
            'CM',
            'CV',
            'DJ',
            'DZ',
            'EG',
            'EH',
            'ER',
            'ET',
            'GA',
            'GH',
            'GM',
            'GN',
            'GQ',
            'GW',
            'KE',
            'KM',
            'LR',
            'LS',
            'LY',
            'MA',
            'MG',
            'ML',
            'MR',
            'MU',
            'MW',
            'MZ',
            'NA',
            'NE',
            'NG',
            'RE',
            'RW',
            'SC',
            'SD',
            'SH',
            'SL',
            'SN',
            'SO',
            'SS',
            'ST',
            'SZ',
            'TD',
            'TG',
            'TN',
            'TZ',
            'UG',
            'YT',
            'ZA',
            'ZM',
            'ZW'
        ]
    },
    'AN': {
        'name': 'Antarctica',
        'countries': [
            'AQ',
            'BV',
            'GS',
            'HM',
            'TF'
        ]
    },
    'AS': {
        'name': 'Asia',
        'countries': [
            'AE',
            'AF',
            'AM',
            'AZ',
            'BD',
            'BH',
            'BN',
            'BT',
            'CC',
            'CN',
            'CX',
            'CY',
            'GE',
            'HK',
            'ID',
            'IL',
            'IN',
            'IO',
            'IQ',
            'IR',
            'JO',
            'JP',
            'KG',
            'KH',
            'KP',
            'KR',
            'KW',
            'KZ',
            'LA',
            'LB',
            'LK',
            'MM',
            'MN',
            'MO',
            'MV',
            'MY',
            'NP',
            'OM',
            'PH',
            'PK',
            'PS',
            'QA',
            'SA',
            'SG',
            'SY',
            'TH',
            'TJ',
            'TL',
            'TM',
            'TW',
            'UZ',
            'VN',
            'YE'
        ]
    },
    'EU': {
        'name': 'Europe',
        'countries': [
            'AD',
            'AL',
            'AT',
            'AX',
            'BA',
            'BE',
            'BG',
            'BY',
            'CH',
            'CY',
            'CZ',
            'DE',
            'DK',
            'EE',
            'ES',
            'FI',
            'FO',
            'FR',
            'GB',
            'GG',
            'GI',
            'GR',
            'HR',
            'HU',
            'IE',
            'IM',
            'IS',
            'IT',
            'JE',
            'LI',
            'LT',
            'LU',
            'LV',
            'MC',
            'MD',
            'ME',
            'MK',
            'MT',
            'NL',
            'NO',
            'PL',
            'PT',
            'RO',
            'RS',
            'RU',
            'SE',
            'SI',
            'SJ',
            'SK',
            'SM',
            'TR',
            'UA',
            'VA'
        ]
    },
    'NA': {
        'name': 'North America',
        'countries': [
            'AG',
            'AI',
            'AW',
            'BB',
            'BL',
            'BM',
            'BQ',
            'BS',
            'BZ',
            'CA',
            'CR',
            'CU',
            'CW',
            'DM',
            'DO',
            'GD',
            'GL',
            'GP',
            'GT',
            'HN',
            'HT',
            'JM',
            'KN',
            'KY',
            'LC',
            'MF',
            'MQ',
            'MS',
            'MX',
            'NI',
            'PA',
            'PM',
            'PR',
            'SV',
            'SX',
            'TC',
            'TT',
            'US',
            'VC',
            'VG',
            'VI'
        ]
    },
    'OC': {
        'name': 'Oceania',
        'countries': [
            'AS',
            'AU',
            'CK',
            'FJ',
            'FM',
            'GU',
            'KI',
            'MH',
            'MP',
            'NC',
            'NF',
            'NR',
            'NU',
            'NZ',
            'PF',
            'PG',
            'PN',
            'PW',
            'SB',
            'TK',
            'TO',
            'TV',
            'UM',
            'VU',
            'WF',
            'WS'
        ]
    },
    'SA': {
        'name': 'South America',
        'countries': [
            'AR',
            'BO',
            'BR',
            'CL',
            'CO',
            'EC',
            'FK',
            'GF',
            'GY',
            'PE',
            'PY',
            'SR',
            'UY',
            'VE'
        ]
    }
};

export const COUNTRIES = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AX', label: 'Åland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'PW', label: 'Belau' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BQ', label: 'Bonaire, Saint Eustatius and Saba' },
    { value: 'BA', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'BN', label: 'Brunei' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CN', label: 'China' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CC', label: 'Cocos (Keeling) Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo (Brazzaville)' },
    { value: 'CD', label: 'Congo (Kinshasa)' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Cura&ccedil;ao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'IR', label: 'Iran' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'CI', label: 'Ivory Coast' },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JP', label: 'Japan' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MO', label: 'Macao' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NP', label: 'Nepal' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'KP', label: 'North Korea' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PS', label: 'Palestinian Territory' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Reunion' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russia' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barth&eacute;lemy' },
    { value: 'SH', label: 'Saint Helena' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin (French part)' },
    { value: 'SX', label: 'Saint Martin (Dutch part)' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'S&atilde;o Tom&eacute; and Pr&iacute;ncipe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SG', label: 'Singapore' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia/Sandwich Islands' },
    { value: 'KR', label: 'South Korea' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syria' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinvaluead and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom (UK)' },
    { value: 'US', label: 'United States (US)' },
    { value: 'UM', label: 'United States (US) Minor Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VA', label: 'Vatican' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VN', label: 'Vietnam' },
    { value: 'VG', label: 'Virgin Islands (British)' },
    { value: 'VI', label: 'Virgin Islands (US)' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'WS', label: 'Samoa' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
];

export const STATES = {
    'AF': [],
    'AO': [ // Angola states.
        { value: 'BGO', label: 'Bengo' },
        { value: 'BLU', label: 'Benguela' },
        { value: 'BIE', label: 'Bié' },
        { value: 'CAB', label: 'Cabinda' },
        { value: 'CNN', label: 'Cunene' },
        { value: 'HUA', label: 'Huambo' },
        { value: 'HUI', label: 'Huíla' },
        { value: 'CCU', label: 'Kuando Kubango' },
        { value: 'CNO', label: 'Kwanza-Norte' },
        { value: 'CUS', label: 'Kwanza-Sul' },
        { value: 'LUA', label: 'Luanda' },
        { value: 'LNO', label: 'Lunda-Norte' },
        { value: 'LSU', label: 'Lunda-Sul' },
        { value: 'MAL', label: 'Malanje' },
        { value: 'MOX', label: 'Moxico' },
        { value: 'NAM', label: 'Namibe' },
        { value: 'UIG', label: 'Uíge' },
        { value: 'ZAI', label: 'Zaire' },
    ],
    'AR': [ // Argentinian provinces.
        { value: 'C', label: 'Ciudad Aut&oacute;noma de Buenos Aires' },
        { value: 'B', label: 'Buenos Aires' },
        { value: 'K', label: 'Catamarca' },
        { value: 'H', label: 'Chaco' },
        { value: 'U', label: 'Chubut' },
        { value: 'X', label: 'C&oacute;rdoba' },
        { value: 'W', label: 'Corrientes' },
        { value: 'E', label: 'Entre R&iacute;os' },
        { value: 'P', label: 'Formosa' },
        { value: 'Y', label: 'Jujuy' },
        { value: 'L', label: 'La Pampa' },
        { value: 'F', label: 'La Rioja' },
        { value: 'M', label: 'Mendoza' },
        { value: 'N', label: 'Misiones' },
        { value: 'Q', label: 'Neuqu&eacute;n' },
        { value: 'R', label: 'R&iacute;o Negro' },
        { value: 'A', label: 'Salta' },
        { value: 'J', label: 'San Juan' },
        { value: 'D', label: 'San Luis' },
        { value: 'Z', label: 'Santa Cruz' },
        { value: 'S', label: 'Santa Fe' },
        { value: 'G', label: 'Santiago del Estero' },
        { value: 'V', label: 'Tierra del Fuego' },
        { value: 'T', label: 'Tucum&aacute;n' },
    ],
    'AT': [],
    'AU': [ // Australian states.
        { value: 'ACT', label: 'Australian Capital Territory' },
        { value: 'NSW', label: 'New South Wales' },
        { value: 'NT', label: 'Northern Territory' },
        { value: 'QLD', label: 'Queensland' },
        { value: 'SA', label: 'South Australia' },
        { value: 'TAS', label: 'Tasmania' },
        { value: 'VIC', label: 'Victoria' },
        { value: 'WA', label: 'Western Australia' },
    ],
    'AX': [],
    'BD': [ // Bangladeshi states (districts}.
        { value: 'BD-05', label: 'Bagerhat' },
        { value: 'BD-01', label: 'Bandarban' },
        { value: 'BD-02', label: 'Barguna' },
        { value: 'BD-06', label: 'Barishal' },
        { value: 'BD-07', label: 'Bhola' },
        { value: 'BD-03', label: 'Bogura' },
        { value: 'BD-04', label: 'Brahmanbaria' },
        { value: 'BD-09', label: 'Chandpur' },
        { value: 'BD-10', label: 'Chattogram' },
        { value: 'BD-12', label: 'Chuadanga' },
        { value: 'BD-11', label: "Cox's Bazar" },
        { value: 'BD-08', label: 'Cumilla' },
        { value: 'BD-13', label: 'Dhaka' },
        { value: 'BD-14', label: 'Dinajpur' },
        { value: 'BD-15', label: 'Farvaluepur ' },
        { value: 'BD-16', label: 'Feni' },
        { value: 'BD-19', label: 'Gaibandha' },
        { value: 'BD-18', label: 'Gazipur' },
        { value: 'BD-17', label: 'Gopalganj' },
        { value: 'BD-20', label: 'Habiganj' },
        { value: 'BD-21', label: 'Jamalpur' },
        { value: 'BD-22', label: 'Jashore' },
        { value: 'BD-25', label: 'Jhalokati' },
        { value: 'BD-23', label: 'Jhenavalueah' },
        { value: 'BD-24', label: 'Joypurhat' },
        { value: 'BD-29', label: 'Khagrachhari' },
        { value: 'BD-27', label: 'Khulna' },
        { value: 'BD-26', label: 'Kishoreganj' },
        { value: 'BD-28', label: 'Kurigram' },
        { value: 'BD-30', label: 'Kushtia' },
        { value: 'BD-31', label: 'Lakshmipur' },
        { value: 'BD-32', label: 'Lalmonirhat' },
        { value: 'BD-36', label: 'Madaripur' },
        { value: 'BD-37', label: 'Magura' },
        { value: 'BD-33', label: 'Manikganj ' },
        { value: 'BD-39', label: 'Meherpur' },
        { value: 'BD-38', label: 'Moulvibazar' },
        { value: 'BD-35', label: 'Munshiganj' },
        { value: 'BD-34', label: 'Mymensingh' },
        { value: 'BD-48', label: 'Naogaon' },
        { value: 'BD-43', label: 'Narail' },
        { value: 'BD-40', label: 'Narayanganj' },
        { value: 'BD-42', label: 'Narsingdi' },
        { value: 'BD-44', label: 'Natore' },
        { value: 'BD-45', label: 'Nawabganj' },
        { value: 'BD-41', label: 'Netrakona' },
        { value: 'BD-46', label: 'Nilphamari' },
        { value: 'BD-47', label: 'Noakhali' },
        { value: 'BD-49', label: 'Pabna' },
        { value: 'BD-52', label: 'Panchagarh' },
        { value: 'BD-51', label: 'Patuakhali' },
        { value: 'BD-50', label: 'Pirojpur' },
        { value: 'BD-53', label: 'Rajbari' },
        { value: 'BD-54', label: 'Rajshahi' },
        { value: 'BD-56', label: 'Rangamati' },
        { value: 'BD-55', label: 'Rangpur' },
        { value: 'BD-58', label: 'Satkhira' },
        { value: 'BD-62', label: 'Shariatpur' },
        { value: 'BD-57', label: 'Sherpur' },
        { value: 'BD-59', label: 'Sirajganj' },
        { value: 'BD-61', label: 'Sunamganj' },
        { value: 'BD-60', label: 'Sylhet' },
        { value: 'BD-63', label: 'Tangail' },
        { value: 'BD-64', label: 'Thakurgaon' },
    ],
    'BE': [],
    'BG': [ // Bulgarian states.
        { value: 'BG-01', label: 'Blagoevgrad' },
        { value: 'BG-02', label: 'Burgas' },
        { value: 'BG-08', label: 'Dobrich' },
        { value: 'BG-07', label: 'Gabrovo' },
        { value: 'BG-26', label: 'Haskovo' },
        { value: 'BG-09', label: 'Kardzhali' },
        { value: 'BG-10', label: 'Kyustendil' },
        { value: 'BG-11', label: 'Lovech' },
        { value: 'BG-12', label: 'Montana' },
        { value: 'BG-13', label: 'Pazardzhik' },
        { value: 'BG-14', label: 'Pernik' },
        { value: 'BG-15', label: 'Pleven' },
        { value: 'BG-16', label: 'Plovdiv' },
        { value: 'BG-17', label: 'Razgrad' },
        { value: 'BG-18', label: 'Ruse' },
        { value: 'BG-27', label: 'Shumen' },
        { value: 'BG-19', label: 'Silistra' },
        { value: 'BG-20', label: 'Sliven' },
        { value: 'BG-21', label: 'Smolyan' },
        { value: 'BG-23', label: 'Sofia' },
        { value: 'BG-22', label: 'Sofia-Grad' },
        { value: 'BG-24', label: 'Stara Zagora' },
        { value: 'BG-25', label: 'Targovishte' },
        { value: 'BG-03', label: 'Varna' },
        { value: 'BG-04', label: 'Veliko Tarnovo' },
        { value: 'BG-05', label: 'Vvaluein' },
        { value: 'BG-06', label: 'Vratsa' },
        { value: 'BG-28', label: 'Yambol' },
    ],
    'BH': [],
    'BI': [],
    'BO': [ // Bolivian states.
        { value: 'B', label: 'Chuquisaca' },
        { value: 'H', label: 'Beni' },
        { value: 'C', label: 'Cochabamba' },
        { value: 'L', label: 'La Paz' },
        { value: 'O', label: 'Oruro' },
        { value: 'N', label: 'Pando' },
        { value: 'P', label: 'Potosí' },
        { value: 'S', label: 'Santa Cruz' },
        { value: 'T', label: 'Tarija' },
    ],
    'BR': [ // Brazillian states.
        { value: 'AC', label: 'Acre' },
        { value: 'AL', label: 'Alagoas' },
        { value: 'AP', label: 'Amap&aacute;' },
        { value: 'AM', label: 'Amazonas' },
        { value: 'BA', label: 'Bahia' },
        { value: 'CE', label: 'Cear&aacute;' },
        { value: 'DF', label: 'Distrito Federal' },
        { value: 'ES', label: 'Esp&iacute;rito Santo' },
        { value: 'GO', label: 'Goi&aacute;s' },
        { value: 'MA', label: 'Maranh&atilde;o' },
        { value: 'MT', label: 'Mato Grosso' },
        { value: 'MS', label: 'Mato Grosso do Sul' },
        { value: 'MG', label: 'Minas Gerais' },
        { value: 'PA', label: 'Par&aacute;' },
        { value: 'PB', label: 'Para&iacute;ba' },
        { value: 'PR', label: 'Paran&aacute;' },
        { value: 'PE', label: 'Pernambuco' },
        { value: 'PI', label: 'Piau&iacute;' },
        { value: 'RJ', label: 'Rio de Janeiro' },
        { value: 'RN', label: 'Rio Grande do Norte' },
        { value: 'RS', label: 'Rio Grande do Sul' },
        { value: 'RO', label: 'Rond&ocirc;nia' },
        { value: 'RR', label: 'Roraima' },
        { value: 'SC', label: 'Santa Catarina' },
        { value: 'SP', label: 'S&atilde;o Paulo' },
        { value: 'SE', label: 'Sergipe' },
        { value: 'TO', label: 'Tocantins' },
    ],
    'CA': [ // Canadian states.
        { value: 'AB', label: 'Alberta' },
        { value: 'BC', label: 'British Columbia' },
        { value: 'MB', label: 'Manitoba' },
        { value: 'NB', label: 'New Brunswick' },
        { value: 'NL', label: 'Newfoundland and Labrador' },
        { value: 'NT', label: 'Northwest Territories' },
        { value: 'NS', label: 'Nova Scotia' },
        { value: 'NU', label: 'Nunavut' },
        { value: 'ON', label: 'Ontario' },
        { value: 'PE', label: 'Prince Edward Island' },
        { value: 'QC', label: 'Quebec' },
        { value: 'SK', label: 'Saskatchewan' },
        { value: 'YT', label: 'Yukon Territory' },
    ],
    'CH': [ // Cantons of Switzerland.
        { value: 'AG', label: 'Aargau' },
        { value: 'AR', label: 'Appenzell Ausserrhoden' },
        { value: 'AI', label: 'Appenzell Innerrhoden' },
        { value: 'BL', label: 'Basel-Landschaft' },
        { value: 'BS', label: 'Basel-Stadt' },
        { value: 'BE', label: 'Bern' },
        { value: 'FR', label: 'Fribourg' },
        { value: 'GE', label: 'Geneva' },
        { value: 'GL', label: 'Glarus' },
        { value: 'GR', label: 'Graub&uuml;nden' },
        { value: 'JU', label: 'Jura' },
        { value: 'LU', label: 'Luzern' },
        { value: 'NE', label: 'Neuch&acirc;tel' },
        { value: 'NW', label: 'Nvaluewalden' },
        { value: 'OW', label: 'Obwalden' },
        { value: 'SH', label: 'Schaffhausen' },
        { value: 'SZ', label: 'Schwyz' },
        { value: 'SO', label: 'Solothurn' },
        { value: 'SG', label: 'St. Gallen' },
        { value: 'TG', label: 'Thurgau' },
        { value: 'TI', label: 'Ticino' },
        { value: 'UR', label: 'Uri' },
        { value: 'VS', label: 'Valais' },
        { value: 'VD', label: 'Vaud' },
        { value: 'ZG', label: 'Zug' },
        { value: 'ZH', label: 'Z&uuml;rich' },
    ],
    'CN': [ // Chinese states.
        { value: 'CN1', label: 'Yunnan / &#20113;&#21335;' },
        { value: 'CN2', label: 'Beijing / &#21271;&#20140;' },
        { value: 'CN3', label: 'Tianjin / &#22825;&#27941;' },
        { value: 'CN4', label: 'Hebei / &#27827;&#21271;' },
        { value: 'CN5', label: 'Shanxi / &#23665;&#35199;' },
        { value: 'CN6', label: 'Inner Mongolia / &#20839;&#33945;&#21476;' },
        { value: 'CN7', label: 'Liaoning / &#36797;&#23425;' },
        { value: 'CN8', label: 'Jilin / &#21513;&#26519;' },
        { value: 'CN9', label: 'Heilongjiang / &#40657;&#40857;&#27743;' },
        { value: 'CN10', label: 'Shanghai / &#19978;&#28023;' },
        { value: 'CN11', label: 'Jiangsu / &#27743;&#33487;' },
        { value: 'CN12', label: 'Zhejiang / &#27993;&#27743;' },
        { value: 'CN13', label: 'Anhui / &#23433;&#24509;' },
        { value: 'CN14', label: 'Fujian / &#31119;&#24314;' },
        { value: 'CN15', label: 'Jiangxi / &#27743;&#35199;' },
        { value: 'CN16', label: 'Shandong / &#23665;&#19996;' },
        { value: 'CN17', label: 'Henan / &#27827;&#21335;' },
        { value: 'CN18', label: 'Hubei / &#28246;&#21271;' },
        { value: 'CN19', label: 'Hunan / &#28246;&#21335;' },
        { value: 'CN20', label: 'Guangdong / &#24191;&#19996;' },
        { value: 'CN21', label: 'Guangxi Zhuang / &#24191;&#35199;&#22766;&#26063;' },
        { value: 'CN22', label: 'Hainan / &#28023;&#21335;' },
        { value: 'CN23', label: 'Chongqing / &#37325;&#24198;' },
        { value: 'CN24', label: 'Sichuan / &#22235;&#24029;' },
        { value: 'CN25', label: 'Guizhou / &#36149;&#24030;' },
        { value: 'CN26', label: 'Shaanxi / &#38485;&#35199;' },
        { value: 'CN27', label: 'Gansu / &#29976;&#32899;' },
        { value: 'CN28', label: 'Qinghai / &#38738;&#28023;' },
        { value: 'CN29', label: 'Ningxia Hui / &#23425;&#22799;' },
        { value: 'CN30', label: 'Macao / &#28595;&#38376;' },
        { value: 'CN31', label: 'Tibet / &#35199;&#34255;' },
        { value: 'CN32', label: 'Xinjiang / &#26032;&#30086;' },
    ],
    'CZ': [],
    'DE': [],
    'DK': [],
    'EE': [],
    'ES': [ // Spanish states.
        { value: 'C', label: 'A Coru&ntilde;a' },
        { value: 'VI', label: 'Araba/&Aacute;lava' },
        { value: 'AB', label: 'Albacete' },
        { value: 'A', label: 'Alicante' },
        { value: 'AL', label: 'Almer&iacute;a' },
        { value: 'O', label: 'Asturias' },
        { value: 'AV', label: '&Aacute;vila' },
        { value: 'BA', label: 'Badajoz' },
        { value: 'PM', label: 'Baleares' },
        { value: 'B', label: 'Barcelona' },
        { value: 'BU', label: 'Burgos' },
        { value: 'CC', label: 'C&aacute;ceres' },
        { value: 'CA', label: 'C&aacute;diz' },
        { value: 'S', label: 'Cantabria' },
        { value: 'CS', label: 'Castell&oacute;n' },
        { value: 'CE', label: 'Ceuta' },
        { value: 'CR', label: 'Ciudad Real' },
        { value: 'CO', label: 'C&oacute;rdoba' },
        { value: 'CU', label: 'Cuenca' },
        { value: 'GI', label: 'Girona' },
        { value: 'GR', label: 'Granada' },
        { value: 'GU', label: 'Guadalajara' },
        { value: 'SS', label: 'Gipuzkoa' },
        { value: 'H', label: 'Huelva' },
        { value: 'HU', label: 'Huesca' },
        { value: 'J', label: 'Ja&eacute;n' },
        { value: 'LO', label: 'La Rioja' },
        { value: 'GC', label: 'Las Palmas' },
        { value: 'LE', label: 'Le&oacute;n' },
        { value: 'L', label: 'Llevaluea' },
        { value: 'LU', label: 'Lugo' },
        { value: 'M', label: 'Madrvalue' },
        { value: 'MA', label: 'M&aacute;laga' },
        { value: 'ML', label: 'Melilla' },
        { value: 'MU', label: 'Murcia' },
        { value: 'NA', label: 'Navarra' },
        { value: 'OR', label: 'Ourense' },
        { value: 'P', label: 'Palencia' },
        { value: 'PO', label: 'Pontevedra' },
        { value: 'SA', label: 'Salamanca' },
        { value: 'TF', label: 'Santa Cruz de Tenerife' },
        { value: 'SG', label: 'Segovia' },
        { value: 'SE', label: 'Sevilla' },
        { value: 'SO', label: 'Soria' },
        { value: 'T', label: 'Tarragona' },
        { value: 'TE', label: 'Teruel' },
        { value: 'TO', label: 'Toledo' },
        { value: 'V', label: 'Valencia' },
        { value: 'VA', label: 'Valladolvalue' },
        { value: 'BI', label: 'Bizkaia' },
        { value: 'ZA', label: 'Zamora' },
        { value: 'Z', label: 'Zaragoza' },
    ],
    'FI': [],
    'FR': [],
    'GP': [],
    'GR': [ // Greek Regions.
        { value: 'I', label: 'Αττική' },
        { value: 'A', label: 'Ανατολική Μακεδονία και Θράκη' },
        { value: 'B', label: 'Κεντρική Μακεδονία' },
        { value: 'C', label: 'Δυτική Μακεδονία' },
        { value: 'D', label: 'Ήπειρος' },
        { value: 'E', label: 'Θεσσαλία' },
        { value: 'F', label: 'Ιόνιοι Νήσοι' },
        { value: 'G', label: 'Δυτική Ελλάδα' },
        { value: 'H', label: 'Στερεά Ελλάδα' },
        { value: 'J', label: 'Πελοπόννησος' },
        { value: 'K', label: 'Βόρειο Αιγαίο' },
        { value: 'L', label: 'Νότιο Αιγαίο' },
        { value: 'M', label: 'Κρήτη' },
    ],
    'GF': [],
    'HK': [ // Hong Kong states.
        { value: 'HONG KONG', label: 'Hong Kong Island' },
        { value: 'KOWLOON', label: 'Kowloon' },
        { value: 'NEW TERRITORIES', label: 'New Territories' },
    ],
    'HU': [ // Hungary states.
        { value: 'BK', label: 'Bács-Kiskun' },
        { value: 'BE', label: 'Békés' },
        { value: 'BA', label: 'Baranya' },
        { value: 'BZ', label: 'Borsod-Abaúj-Zemplén' },
        { value: 'BU', label: 'Budapest' },
        { value: 'CS', label: 'Csongrád' },
        { value: 'FE', label: 'Fejér' },
        { value: 'GS', label: 'Győr-Moson-Sopron' },
        { value: 'HB', label: 'Hajdú-Bihar' },
        { value: 'HE', label: 'Heves' },
        { value: 'JN', label: 'Jász-Nagykun-Szolnok' },
        { value: 'KE', label: 'Komárom-Esztergom' },
        { value: 'NO', label: 'Nógrád' },
        { value: 'PE', label: 'Pest' },
        { value: 'SO', label: 'Somogy' },
        { value: 'SZ', label: 'Szabolcs-Szatmár-Bereg' },
        { value: 'TO', label: 'Tolna' },
        { value: 'VA', label: 'Vas' },
        { value: 'VE', label: 'Veszprém' },
        { value: 'ZA', label: 'Zala' },
    ],
    'ID': [ // Indonesia Provinces.
        { value: 'AC', label: 'Daerah Istimewa Aceh' },
        { value: 'SU', label: 'Sumatera Utara' },
        { value: 'SB', label: 'Sumatera Barat' },
        { value: 'RI', label: 'Riau' },
        { value: 'KR', label: 'Kepulauan Riau' },
        { value: 'JA', label: 'Jambi' },
        { value: 'SS', label: 'Sumatera Selatan' },
        { value: 'BB', label: 'Bangka Belitung' },
        { value: 'BE', label: 'Bengkulu' },
        { value: 'LA', label: 'Lampung' },
        { value: 'JK', label: 'DKI Jakarta' },
        { value: 'JB', label: 'Jawa Barat' },
        { value: 'BT', label: 'Banten' },
        { value: 'JT', label: 'Jawa Tengah' },
        { value: 'JI', label: 'Jawa Timur' },
        { value: 'YO', label: 'Daerah Istimewa Yogyakarta' },
        { value: 'BA', label: 'Bali' },
        { value: 'NB', label: 'Nusa Tenggara Barat' },
        { value: 'NT', label: 'Nusa Tenggara Timur' },
        { value: 'KB', label: 'Kalimantan Barat' },
        { value: 'KT', label: 'Kalimantan Tengah' },
        { value: 'KI', label: 'Kalimantan Timur' },
        { value: 'KS', label: 'Kalimantan Selatan' },
        { value: 'KU', label: 'Kalimantan Utara' },
        { value: 'SA', label: 'Sulawesi Utara' },
        { value: 'ST', label: 'Sulawesi Tengah' },
        { value: 'SG', label: 'Sulawesi Tenggara' },
        { value: 'SR', label: 'Sulawesi Barat' },
        { value: 'SN', label: 'Sulawesi Selatan' },
        { value: 'GO', label: 'Gorontalo' },
        { value: 'MA', label: 'Maluku' },
        { value: 'MU', label: 'Maluku Utara' },
        { value: 'PA', label: 'Papua' },
        { value: 'PB', label: 'Papua Barat' },
    ],
    'IE': [ // Republic of Ireland.
        { value: 'CW', label: 'Carlow' },
        { value: 'CN', label: 'Cavan' },
        { value: 'CE', label: 'Clare' },
        { value: 'CO', label: 'Cork' },
        { value: 'DL', label: 'Donegal' },
        { value: 'D', label: 'Dublin' },
        { value: 'G', label: 'Galway' },
        { value: 'KY', label: 'Kerry' },
        { value: 'KE', label: 'Kildare' },
        { value: 'KK', label: 'Kilkenny' },
        { value: 'LS', label: 'Laois' },
        { value: 'LM', label: 'Leitrim' },
        { value: 'LK', label: 'Limerick' },
        { value: 'LD', label: 'Longford' },
        { value: 'LH', label: 'Louth' },
        { value: 'MO', label: 'Mayo' },
        { value: 'MH', label: 'Meath' },
        { value: 'MN', label: 'Monaghan' },
        { value: 'OY', label: 'Offaly' },
        { value: 'RN', label: 'Roscommon' },
        { value: 'SO', label: 'Sligo' },
        { value: 'TA', label: 'Tipperary' },
        { value: 'WD', label: 'Waterford' },
        { value: 'WH', label: 'Westmeath' },
        { value: 'WX', label: 'Wexford' },
        { value: 'WW', label: 'Wicklow' },
    ],
    'IN': [ // Indian states.
        { value: 'AP', label: 'Andhra Pradesh' },
        { value: 'AR', label: 'Arunachal Pradesh' },
        { value: 'AS', label: 'Assam' },
        { value: 'BR', label: 'Bihar' },
        { value: 'CT', label: 'Chhattisgarh' },
        { value: 'GA', label: 'Goa' },
        { value: 'GJ', label: 'Gujarat' },
        { value: 'HR', label: 'Haryana' },
        { value: 'HP', label: 'Himachal Pradesh' },
        { value: 'JK', label: 'Jammu and Kashmir' },
        { value: 'JH', label: 'Jharkhand' },
        { value: 'KA', label: 'Karnataka' },
        { value: 'KL', label: 'Kerala' },
        { value: 'MP', label: 'Madhya Pradesh' },
        { value: 'MH', label: 'Maharashtra' },
        { value: 'MN', label: 'Manipur' },
        { value: 'ML', label: 'Meghalaya' },
        { value: 'MZ', label: 'Mizoram' },
        { value: 'NL', label: 'Nagaland' },
        { value: 'OR', label: 'Orissa' },
        { value: 'PB', label: 'Punjab' },
        { value: 'RJ', label: 'Rajasthan' },
        { value: 'SK', label: 'Sikkim' },
        { value: 'TN', label: 'Tamil Nadu' },
        { value: 'TS', label: 'Telangana' },
        { value: 'TR', label: 'Tripura' },
        { value: 'UK', label: 'Uttarakhand' },
        { value: 'UP', label: 'Uttar Pradesh' },
        { value: 'WB', label: 'West Bengal' },
        { value: 'AN', label: 'Andaman and Nicobar Islands' },
        { value: 'CH', label: 'Chandigarh' },
        { value: 'DN', label: 'Dadra and Nagar Haveli' },
        { value: 'DD', label: 'Daman and Diu' },
        { value: 'DL', label: 'Delhi' },
        { value: 'LD', label: 'Lakshadeep' },
        { value: 'PY', label: 'Pondicherry (Puducherry)' },
    ],
    'IR': [ // Iran States.
        { value: 'KHZ', label: 'Khuzestan  (خوزستان)' },
        { value: 'THR', label: 'Tehran  (تهران)' },
        { value: 'ILM', label: 'Ilaam (ایلام)' },
        { value: 'BHR', label: 'Bushehr (بوشهر)' },
        { value: 'ADL', label: 'Ardabil (اردبیل)' },
        { value: 'ESF', label: 'Isfahan (اصفهان)' },
        { value: 'YZD', label: 'Yazd (یزد)' },
        { value: 'KRH', label: 'Kermanshah (کرمانشاه)' },
        { value: 'KRN', label: 'Kerman (کرمان)' },
        { value: 'HDN', label: 'Hamadan (همدان)' },
        { value: 'GZN', label: 'Ghazvin (قزوین)' },
        { value: 'ZJN', label: 'Zanjan (زنجان)' },
        { value: 'LRS', label: 'Luristan (لرستان)' },
        { value: 'ABZ', label: 'Alborz (البرز)' },
        { value: 'EAZ', label: 'East Azarbaijan (آذربایجان شرقی)' },
        { value: 'WAZ', label: 'West Azarbaijan (آذربایجان غربی)' },
        { value: 'CHB', label: 'Chaharmahal and Bakhtiari (چهارمحال و بختیاری)' },
        { value: 'SKH', label: 'South Khorasan (خراسان جنوبی)' },
        { value: 'RKH', label: 'Razavi Khorasan (خراسان رضوی)' },
        { value: 'NKH', label: 'North Khorasan (خراسان شمالی)' },
        { value: 'SMN', label: 'Semnan (سمنان)' },
        { value: 'FRS', label: 'Fars (فارس)' },
        { value: 'QHM', label: 'Qom (قم)' },
        { value: 'KRD', label: 'Kurdistan / کردستان)' },
        { value: 'KBD', label: 'Kohgiluyeh and BoyerAhmad (کهگیلوییه و بویراحمد)' },
        { value: 'GLS', label: 'Golestan (گلستان)' },
        { value: 'GIL', label: 'Gilan (گیلان)' },
        { value: 'MZN', label: 'Mazandaran (مازندران)' },
        { value: 'MKZ', label: 'Markazi (مرکزی)' },
        { value: 'HRZ', label: 'Hormozgan (هرمزگان)' },
        { value: 'SBN', label: 'Sistan and Baluchestan (سیستان و بلوچستان)' },
    ],
    'IS': [],
    'IT': [ // Italy Provinces.
        { value: 'AG', label: 'Agrigento' },
        { value: 'AL', label: 'Alessandria' },
        { value: 'AN', label: 'Ancona' },
        { value: 'AO', label: 'Aosta' },
        { value: 'AR', label: 'Arezzo' },
        { value: 'AP', label: 'Ascoli Piceno' },
        { value: 'AT', label: 'Asti' },
        { value: 'AV', label: 'Avellino' },
        { value: 'BA', label: 'Bari' },
        { value: 'BT', label: 'Barletta-Andria-Trani' },
        { value: 'BL', label: 'Belluno' },
        { value: 'BN', label: 'Benevento' },
        { value: 'BG', label: 'Bergamo' },
        { value: 'BI', label: 'Biella' },
        { value: 'BO', label: 'Bologna' },
        { value: 'BZ', label: 'Bolzano' },
        { value: 'BS', label: 'Brescia' },
        { value: 'BR', label: 'Brindisi' },
        { value: 'CA', label: 'Cagliari' },
        { value: 'CL', label: 'Caltanissetta' },
        { value: 'CB', label: 'Campobasso' },
        { value: 'CE', label: 'Caserta' },
        { value: 'CT', label: 'Catania' },
        { value: 'CZ', label: 'Catanzaro' },
        { value: 'CH', label: 'Chieti' },
        { value: 'CO', label: 'Como' },
        { value: 'CS', label: 'Cosenza' },
        { value: 'CR', label: 'Cremona' },
        { value: 'KR', label: 'Crotone' },
        { value: 'CN', label: 'Cuneo' },
        { value: 'EN', label: 'Enna' },
        { value: 'FM', label: 'Fermo' },
        { value: 'FE', label: 'Ferrara' },
        { value: 'FI', label: 'Firenze' },
        { value: 'FG', label: 'Foggia' },
        { value: 'FC', label: 'Forlì-Cesena' },
        { value: 'FR', label: 'Frosinone' },
        { value: 'GE', label: 'Genova' },
        { value: 'GO', label: 'Gorizia' },
        { value: 'GR', label: 'Grosseto' },
        { value: 'IM', label: 'Imperia' },
        { value: 'IS', label: 'Isernia' },
        { value: 'SP', label: 'La Spezia' },
        { value: 'AQ', label: "L'Aquila" },
        { value: 'LT', label: 'Latina' },
        { value: 'LE', label: 'Lecce' },
        { value: 'LC', label: 'Lecco' },
        { value: 'LI', label: 'Livorno' },
        { value: 'LO', label: 'Lodi' },
        { value: 'LU', label: 'Lucca' },
        { value: 'MC', label: 'Macerata' },
        { value: 'MN', label: 'Mantova' },
        { value: 'MS', label: 'Massa-Carrara' },
        { value: 'MT', label: 'Matera' },
        { value: 'ME', label: 'Messina' },
        { value: 'MI', label: 'Milano' },
        { value: 'MO', label: 'Modena' },
        { value: 'MB', label: 'Monza e della Brianza' },
        { value: 'NA', label: 'Napoli' },
        { value: 'NO', label: 'Novara' },
        { value: 'NU', label: 'Nuoro' },
        { value: 'OR', label: 'Oristano' },
        { value: 'PD', label: 'Padova' },
        { value: 'PA', label: 'Palermo' },
        { value: 'PR', label: 'Parma' },
        { value: 'PV', label: 'Pavia' },
        { value: 'PG', label: 'Perugia' },
        { value: 'PU', label: 'Pesaro e Urbino' },
        { value: 'PE', label: 'Pescara' },
        { value: 'PC', label: 'Piacenza' },
        { value: 'PI', label: 'Pisa' },
        { value: 'PT', label: 'Pistoia' },
        { value: 'PN', label: 'Pordenone' },
        { value: 'PZ', label: 'Potenza' },
        { value: 'PO', label: 'Prato' },
        { value: 'RG', label: 'Ragusa' },
        { value: 'RA', label: 'Ravenna' },
        { value: 'RC', label: 'Reggio Calabria' },
        { value: 'RE', label: 'Reggio Emilia' },
        { value: 'RI', label: 'Rieti' },
        { value: 'RN', label: 'Rimini' },
        { value: 'RM', label: 'Roma' },
        { value: 'RO', label: 'Rovigo' },
        { value: 'SA', label: 'Salerno' },
        { value: 'SS', label: 'Sassari' },
        { value: 'SV', label: 'Savona' },
        { value: 'SI', label: 'Siena' },
        { value: 'SR', label: 'Siracusa' },
        { value: 'SO', label: 'Sondrio' },
        { value: 'SU', label: 'Sud Sardegna' },
        { value: 'TA', label: 'Taranto' },
        { value: 'TE', label: 'Teramo' },
        { value: 'TR', label: 'Terni' },
        { value: 'TO', label: 'Torino' },
        { value: 'TP', label: 'Trapani' },
        { value: 'TN', label: 'Trento' },
        { value: 'TV', label: 'Treviso' },
        { value: 'TS', label: 'Trieste' },
        { value: 'UD', label: 'Udine' },
        { value: 'VA', label: 'Varese' },
        { value: 'VE', label: 'Venezia' },
        { value: 'VB', label: 'Verbano-Cusio-Ossola' },
        { value: 'VC', label: 'Vercelli' },
        { value: 'VR', label: 'Verona' },
        { value: 'VV', label: 'Vibo Valentia' },
        { value: 'VI', label: 'Vicenza' },
        { value: 'VT', label: 'Viterbo' },
    ],
    'IL': [],
    'IM': [],

    /**
     * Japan States.
     *
     * English notation of prefectures conform to the notation of Japan Post.
     * The suffix corresponds with the Japanese translation file.
     */
    'JP': [
        { value: 'JP01', label: 'Hokkavalueo' },
        { value: 'JP02', label: 'Aomori' },
        { value: 'JP03', label: 'Iwate' },
        { value: 'JP04', label: 'Miyagi' },
        { value: 'JP05', label: 'Akita' },
        { value: 'JP06', label: 'Yamagata' },
        { value: 'JP07', label: 'Fukushima' },
        { value: 'JP08', label: 'Ibaraki' },
        { value: 'JP09', label: 'Tochigi' },
        { value: 'JP10', label: 'Gunma' },
        { value: 'JP11', label: 'Saitama' },
        { value: 'JP12', label: 'Chiba' },
        { value: 'JP13', label: 'Tokyo' },
        { value: 'JP14', label: 'Kanagawa' },
        { value: 'JP15', label: 'Niigata' },
        { value: 'JP16', label: 'Toyama' },
        { value: 'JP17', label: 'Ishikawa' },
        { value: 'JP18', label: 'Fukui' },
        { value: 'JP19', label: 'Yamanashi' },
        { value: 'JP20', label: 'Nagano' },
        { value: 'JP21', label: 'Gifu' },
        { value: 'JP22', label: 'Shizuoka' },
        { value: 'JP23', label: 'Aichi' },
        { value: 'JP24', label: 'Mie' },
        { value: 'JP25', label: 'Shiga' },
        { value: 'JP26', label: 'Kyoto' },
        { value: 'JP27', label: 'Osaka' },
        { value: 'JP28', label: 'Hyogo' },
        { value: 'JP29', label: 'Nara' },
        { value: 'JP30', label: 'Wakayama' },
        { value: 'JP31', label: 'Tottori' },
        { value: 'JP32', label: 'Shimane' },
        { value: 'JP33', label: 'Okayama' },
        { value: 'JP34', label: 'Hiroshima' },
        { value: 'JP35', label: 'Yamaguchi' },
        { value: 'JP36', label: 'Tokushima' },
        { value: 'JP37', label: 'Kagawa' },
        { value: 'JP38', label: 'Ehime' },
        { value: 'JP39', label: 'Kochi' },
        { value: 'JP40', label: 'Fukuoka' },
        { value: 'JP41', label: 'Saga' },
        { value: 'JP42', label: 'Nagasaki' },
        { value: 'JP43', label: 'Kumamoto' },
        { value: 'JP44', label: 'Oita' },
        { value: 'JP45', label: 'Miyazaki' },
        { value: 'JP46', label: 'Kagoshima' },
        { value: 'JP47', label: 'Okinawa' },
    ],
    'KR': [],
    'KW': [],
    'LA': [
        { value: 'AT', label: 'Attapeu' },
        { value: 'BK', label: 'Bokeo' },
        { value: 'BL', label: 'Bolikhamsai' },
        { value: 'CH', label: 'Champasak' },
        { value: 'HO', label: 'Houaphanh' },
        { value: 'KH', label: 'Khammouane' },
        { value: 'LM', label: 'Luang Namtha' },
        { value: 'LP', label: 'Luang Prabang' },
        { value: 'OU', label: 'Oudomxay' },
        { value: 'PH', label: 'Phongsaly' },
        { value: 'SL', label: 'Salavan' },
        { value: 'SV', label: 'Savannakhet' },
        { value: 'VI', label: 'Vientiane Province' },
        { value: 'VT', label: 'Vientiane' },
        { value: 'XA', label: 'Sainyabuli' },
        { value: 'XE', label: 'Sekong' },
        { value: 'XI', label: 'Xiangkhouang' },
        { value: 'XS', label: 'Xaisomboun' },
    ],
    'LB': [],
    'LR': [ // Liberia provinces.
        { value: 'BM', label: 'Bomi' },
        { value: 'BN', label: 'Bong' },
        { value: 'GA', label: 'Gbarpolu' },
        { value: 'GB', label: 'Grand Bassa' },
        { value: 'GC', label: 'Grand Cape Mount' },
        { value: 'GG', label: 'Grand Gedeh' },
        { value: 'GK', label: 'Grand Kru' },
        { value: 'LO', label: 'Lofa' },
        { value: 'MA', label: 'Margibi' },
        { value: 'MY', label: 'Maryland' },
        { value: 'MO', label: 'Montserrado' },
        { value: 'NM', label: 'Nimba' },
        { value: 'RV', label: 'Rivercess' },
        { value: 'RG', label: 'River Gee' },
        { value: 'SN', label: 'Sinoe' },
    ],
    'LU': [],
    'MD': [ // Moldova states.
        { value: 'C', label: 'Chi&#537;in&#259;u' },
        { value: 'BL', label: 'B&#259;l&#539;i' },
        { value: 'AN', label: 'Anenii Noi' },
        { value: 'BS', label: 'Basarabeasca' },
        { value: 'BR', label: 'Briceni' },
        { value: 'CH', label: 'Cahul' },
        { value: 'CT', label: 'Cantemir' },
        { value: 'CL', label: 'C&#259;l&#259;ra&#537;i' },
        { value: 'CS', label: 'C&#259;u&#537;eni' },
        { value: 'CM', label: 'Cimi&#537;lia' },
        { value: 'CR', label: 'Criuleni' },
        { value: 'DN', label: 'Dondu&#537;eni' },
        { value: 'DR', label: 'Drochia' },
        { value: 'DB', label: 'Dub&#259;sari' },
        { value: 'ED', label: 'Edine&#539;' },
        { value: 'FL', label: 'F&#259;le&#537;ti' },
        { value: 'FR', label: 'Flore&#537;ti' },
        { value: 'GE', label: 'UTA G&#259;g&#259;uzia' },
        { value: 'GL', label: 'Glodeni' },
        { value: 'HN', label: 'H&icirc;nce&#537;ti' },
        { value: 'IL', label: 'Ialoveni' },
        { value: 'LV', label: 'Leova' },
        { value: 'NS', label: 'Nisporeni' },
        { value: 'OC', label: 'Ocni&#539;a' },
        { value: 'OR', label: 'Orhei' },
        { value: 'RZ', label: 'Rezina' },
        { value: 'RS', label: 'R&icirc;&#537;cani' },
        { value: 'SG', label: 'S&icirc;ngerei' },
        { value: 'SR', label: 'Soroca' },
        { value: 'ST', label: 'Str&#259;&#537;eni' },
        { value: 'SD', label: '&#536;old&#259;ne&#537;ti' },
        { value: 'SV', label: '&#536;tefan Vod&#259;' },
        { value: 'TR', label: 'Taraclia' },
        { value: 'TL', label: 'Telene&#537;ti' },
        { value: 'UN', label: 'Ungheni' },
    ],
    'MQ': [],
    'MT': [],
    'MX': [ // Mexico States.
        { value: 'DF', label: 'Ciudad de M&eacute;xico' },
        { value: 'JA', label: 'Jalisco' },
        { value: 'NL', label: 'Nuevo Le&oacute;n' },
        { value: 'AG', label: 'Aguascalientes' },
        { value: 'BC', label: 'Baja California' },
        { value: 'BS', label: 'Baja California Sur' },
        { value: 'CM', label: 'Campeche' },
        { value: 'CS', label: 'Chiapas' },
        { value: 'CH', label: 'Chihuahua' },
        { value: 'CO', label: 'Coahuila' },
        { value: 'CL', label: 'Colima' },
        { value: 'DG', label: 'Durango' },
        { value: 'GT', label: 'Guanajuato' },
        { value: 'GR', label: 'Guerrero' },
        { value: 'HG', label: 'Hvaluealgo' },
        { value: 'MX', label: 'Estado de M&eacute;xico' },
        { value: 'MI', label: 'Michoac&aacute;n' },
        { value: 'MO', label: 'Morelos' },
        { value: 'NA', label: 'Nayarit' },
        { value: 'OA', label: 'Oaxaca' },
        { value: 'PU', label: 'Puebla' },
        { value: 'QT', label: 'Quer&eacute;taro' },
        { value: 'QR', label: 'Quintana Roo' },
        { value: 'SL', label: 'San Luis Potos&iacute;' },
        { value: 'SI', label: 'Sinaloa' },
        { value: 'SO', label: 'Sonora' },
        { value: 'TB', label: 'Tabasco' },
        { value: 'TM', label: 'Tamaulipas' },
        { value: 'TL', label: 'Tlaxcala' },
        { value: 'VE', label: 'Veracruz' },
        { value: 'YU', label: 'Yucat&aacute;n' },
        { value: 'ZA', label: 'Zacatecas' },
    ],
    'MY': [ // Malaysian states.
        { value: 'JHR', label: 'Johor' },
        { value: 'KDH', label: 'Kedah' },
        { value: 'KTN', label: 'Kelantan' },
        { value: 'LBN', label: 'Labuan' },
        { value: 'MLK', label: 'Malacca (Melaka)' },
        { value: 'NSN', label: 'Negeri Sembilan' },
        { value: 'PHG', label: 'Pahang' },
        { value: 'PNG', label: 'Penang (Pulau Pinang)' },
        { value: 'PRK', label: 'Perak' },
        { value: 'PLS', label: 'Perlis' },
        { value: 'SBH', label: 'Sabah' },
        { value: 'SWK', label: 'Sarawak' },
        { value: 'SGR', label: 'Selangor' },
        { value: 'TRG', label: 'Terengganu' },
        { value: 'PJY', label: 'Putrajaya' },
        { value: 'KUL', label: 'Kuala Lumpur' },
    ],
    'NG': [ // Nigerian provinces.
        { value: 'AB', label: 'Abia' },
        { value: 'FC', label: 'Abuja' },
        { value: 'AD', label: 'Adamawa' },
        { value: 'AK', label: 'Akwa Ibom' },
        { value: 'AN', label: 'Anambra' },
        { value: 'BA', label: 'Bauchi' },
        { value: 'BY', label: 'Bayelsa' },
        { value: 'BE', label: 'Benue' },
        { value: 'BO', label: 'Borno' },
        { value: 'CR', label: 'Cross River' },
        { value: 'DE', label: 'Delta' },
        { value: 'EB', label: 'Ebonyi' },
        { value: 'ED', label: 'Edo' },
        { value: 'EK', label: 'Ekiti' },
        { value: 'EN', label: 'Enugu' },
        { value: 'GO', label: 'Gombe' },
        { value: 'IM', label: 'Imo' },
        { value: 'JI', label: 'Jigawa' },
        { value: 'KD', label: 'Kaduna' },
        { value: 'KN', label: 'Kano' },
        { value: 'KT', label: 'Katsina' },
        { value: 'KE', label: 'Kebbi' },
        { value: 'KO', label: 'Kogi' },
        { value: 'KW', label: 'Kwara' },
        { value: 'LA', label: 'Lagos' },
        { value: 'NA', label: 'Nasarawa' },
        { value: 'NI', label: 'Niger' },
        { value: 'OG', label: 'Ogun' },
        { value: 'ON', label: 'Ondo' },
        { value: 'OS', label: 'Osun' },
        { value: 'OY', label: 'Oyo' },
        { value: 'PL', label: 'Plateau' },
        { value: 'RI', label: 'Rivers' },
        { value: 'SO', label: 'Sokoto' },
        { value: 'TA', label: 'Taraba' },
        { value: 'YO', label: 'Yobe' },
        { value: 'ZA', label: 'Zamfara' },
    ],
    'NL': [],
    'NO': [],
    'NP': [ // Nepal states (Zones}.
        { value: 'BAG', label: 'Bagmati' },
        { value: 'BHE', label: 'Bheri' },
        { value: 'DHA', label: 'Dhaulagiri' },
        { value: 'GAN', label: 'Gandaki' },
        { value: 'JAN', label: 'Janakpur' },
        { value: 'KAR', label: 'Karnali' },
        { value: 'KOS', label: 'Koshi' },
        { value: 'LUM', label: 'Lumbini' },
        { value: 'MAH', label: 'Mahakali' },
        { value: 'MEC', label: 'Mechi' },
        { value: 'NAR', label: 'Narayani' },
        { value: 'RAP', label: 'Rapti' },
        { value: 'SAG', label: 'Sagarmatha' },
        { value: 'SET', label: 'Seti' },
    ],
    'NZ': [ // New Zealand States.
        { value: 'NL', label: 'Northland' },
        { value: 'AK', label: 'Auckland' },
        { value: 'WA', label: 'Waikato' },
        { value: 'BP', label: 'Bay of Plenty' },
        { value: 'TK', label: 'Taranaki' },
        { value: 'GI', label: 'Gisborne' },
        { value: 'HB', label: 'Hawke&rsquo;s Bay' },
        { value: 'MW', label: 'Manawatu-Wanganui' },
        { value: 'WE', label: 'Wellington' },
        { value: 'NS', label: 'Nelson' },
        { value: 'MB', label: 'Marlborough' },
        { value: 'TM', label: 'Tasman' },
        { value: 'WC', label: 'West Coast' },
        { value: 'CT', label: 'Canterbury' },
        { value: 'OT', label: 'Otago' },
        { value: 'SL', label: 'Southland' },
    ],
    'PE': [ // Peru states.
        { value: 'CAL', label: 'El Callao' },
        { value: 'LMA', label: 'Municipalvaluead Metropolitana de Lima' },
        { value: 'AMA', label: 'Amazonas' },
        { value: 'ANC', label: 'Ancash' },
        { value: 'APU', label: 'Apur&iacute;mac' },
        { value: 'ARE', label: 'Arequipa' },
        { value: 'AYA', label: 'Ayacucho' },
        { value: 'CAJ', label: 'Cajamarca' },
        { value: 'CUS', label: 'Cusco' },
        { value: 'HUV', label: 'Huancavelica' },
        { value: 'HUC', label: 'Hu&aacute;nuco' },
        { value: 'ICA', label: 'Ica' },
        { value: 'JUN', label: 'Jun&iacute;n' },
        { value: 'LAL', label: 'La Libertad' },
        { value: 'LAM', label: 'Lambayeque' },
        { value: 'LIM', label: 'Lima' },
        { value: 'LOR', label: 'Loreto' },
        { value: 'MDD', label: 'Madre de Dios' },
        { value: 'MOQ', label: 'Moquegua' },
        { value: 'PAS', label: 'Pasco' },
        { value: 'PIU', label: 'Piura' },
        { value: 'PUN', label: 'Puno' },
        { value: 'SAM', label: 'San Mart&iacute;n' },
        { value: 'TAC', label: 'Tacna' },
        { value: 'TUM', label: 'Tumbes' },
        { value: 'UCA', label: 'Ucayali' },
    ],

    /**
     * Philippine Provinces.
     */
    'PH': [
        { value: 'ABR', label: 'Abra' },
        { value: 'AGN', label: 'Agusan del Norte' },
        { value: 'AGS', label: 'Agusan del Sur' },
        { value: 'AKL', label: 'Aklan' },
        { value: 'ALB', label: 'Albay' },
        { value: 'ANT', label: 'Antique' },
        { value: 'APA', label: 'Apayao' },
        { value: 'AUR', label: 'Aurora' },
        { value: 'BAS', label: 'Basilan' },
        { value: 'BAN', label: 'Bataan' },
        { value: 'BTN', label: 'Batanes' },
        { value: 'BTG', label: 'Batangas' },
        { value: 'BEN', label: 'Benguet' },
        { value: 'BIL', label: 'Biliran' },
        { value: 'BOH', label: 'Bohol' },
        { value: 'BUK', label: 'Bukvaluenon' },
        { value: 'BUL', label: 'Bulacan' },
        { value: 'CAG', label: 'Cagayan' },
        { value: 'CAN', label: 'Camarines Norte' },
        { value: 'CAS', label: 'Camarines Sur' },
        { value: 'CAM', label: 'Camiguin' },
        { value: 'CAP', label: 'Capiz' },
        { value: 'CAT', label: 'Catanduanes' },
        { value: 'CAV', label: 'Cavite' },
        { value: 'CEB', label: 'Cebu' },
        { value: 'COM', label: 'Compostela Valley' },
        { value: 'NCO', label: 'Cotabato' },
        { value: 'DAV', label: 'Davao del Norte' },
        { value: 'DAS', label: 'Davao del Sur' },
        { value: 'DAC', label: 'Davao Occvalueental' },
        { value: 'DAO', label: 'Davao Oriental' },
        { value: 'DIN', label: 'Dinagat Islands' },
        { value: 'EAS', label: 'Eastern Samar' },
        { value: 'GUI', label: 'Guimaras' },
        { value: 'IFU', label: 'Ifugao' },
        { value: 'ILN', label: 'Ilocos Norte' },
        { value: 'ILS', label: 'Ilocos Sur' },
        { value: 'ILI', label: 'Iloilo' },
        { value: 'ISA', label: 'Isabela' },
        { value: 'KAL', label: 'Kalinga' },
        { value: 'LUN', label: 'La Union' },
        { value: 'LAG', label: 'Laguna' },
        { value: 'LAN', label: 'Lanao del Norte' },
        { value: 'LAS', label: 'Lanao del Sur' },
        { value: 'LEY', label: 'Leyte' },
        { value: 'MAG', label: 'Maguindanao' },
        { value: 'MAD', label: 'Marinduque' },
        { value: 'MAS', label: 'Masbate' },
        { value: 'MSC', label: 'Misamis Occvalueental' },
        { value: 'MSR', label: 'Misamis Oriental' },
        { value: 'MOU', label: 'Mountain Province' },
        { value: 'NEC', label: 'Negros Occvalueental' },
        { value: 'NER', label: 'Negros Oriental' },
        { value: 'NSA', label: 'Northern Samar' },
        { value: 'NUE', label: 'Nueva Ecija' },
        { value: 'NUV', label: 'Nueva Vizcaya' },
        { value: 'MDC', label: 'Occvalueental Mindoro' },
        { value: 'MDR', label: 'Oriental Mindoro' },
        { value: 'PLW', label: 'Palawan' },
        { value: 'PAM', label: 'Pampanga' },
        { value: 'PAN', label: 'Pangasinan' },
        { value: 'QUE', label: 'Quezon' },
        { value: 'QUI', label: 'Quirino' },
        { value: 'RIZ', label: 'Rizal' },
        { value: 'ROM', label: 'Romblon' },
        { value: 'WSA', label: 'Samar' },
        { value: 'SAR', label: 'Sarangani' },
        { value: 'SIQ', label: 'Siquijor' },
        { value: 'SOR', label: 'Sorsogon' },
        { value: 'SCO', label: 'South Cotabato' },
        { value: 'SLE', label: 'Southern Leyte' },
        { value: 'SUK', label: 'Sultan Kudarat' },
        { value: 'SLU', label: 'Sulu' },
        { value: 'SUN', label: 'Surigao del Norte' },
        { value: 'SUR', label: 'Surigao del Sur' },
        { value: 'TAR', label: 'Tarlac' },
        { value: 'TAW', label: 'Tawi-Tawi' },
        { value: 'ZMB', label: 'Zambales' },
        { value: 'ZAN', label: 'Zamboanga del Norte' },
        { value: 'ZAS', label: 'Zamboanga del Sur' },
        { value: 'ZSI', label: 'Zamboanga Sibugay' },
        { value: '00', label: 'Metro Manila' },
    ],
    'PK': [ // Pakistan's states.
        { value: 'JK', label: 'Azad Kashmir' },
        { value: 'BA', label: 'Balochistan' },
        { value: 'TA', label: 'FATA' },
        { value: 'GB', label: 'Gilgit Baltistan' },
        { value: 'IS', label: 'Islamabad Capital Territory' },
        { value: 'KP', label: 'Khyber Pakhtunkhwa' },
        { value: 'PB', label: 'Punjab' },
        { value: 'SD', label: 'Sindh' },
    ],
    'PL': [],
    'PT': [],
    'PY': [ // Paraguay states.
        { value: 'PY-ASU', label: 'Asunci&oacute;n' },
        { value: 'PY-1', label: 'Concepci&oacute;n' },
        { value: 'PY-2', label: 'San Pedro' },
        { value: 'PY-3', label: 'Cordillera' },
        { value: 'PY-4', label: 'Guair&aacute;' },
        { value: 'PY-5', label: 'Caaguaz&uacute;' },
        { value: 'PY-6', label: 'Caazap&aacute;' },
        { value: 'PY-7', label: 'Itap&uacute;a' },
        { value: 'PY-8', label: 'Misiones' },
        { value: 'PY-9', label: 'Paraguar&iacute;' },
        { value: 'PY-10', label: 'Alto Paran&aacute;' },
        { value: 'PY-11', label: 'Central' },
        { value: 'PY-12', label: '&Ntilde;eembuc&uacute;' },
        { value: 'PY-13', label: 'Amambay' },
        { value: 'PY-14', label: 'Canindey&uacute;' },
        { value: 'PY-15', label: 'Presvalueente Hayes' },
        { value: 'PY-16', label: 'Alto Paraguay' },
        { value: 'PY-17', label: 'Boquer&oacute;n' },
    ],
    'RE': [],
    'RO': [ // Romania states.
        { value: 'AB', label: 'Alba' },
        { value: 'AR', label: 'Arad' },
        { value: 'AG', label: 'Arge&#537;' },
        { value: 'BC', label: 'Bac&#259;u' },
        { value: 'BH', label: 'Bihor' },
        { value: 'BN', label: 'Bistri&#539;a-N&#259;s&#259;ud' },
        { value: 'BT', label: 'Boto&#537;ani' },
        { value: 'BR', label: 'Br&#259;ila' },
        { value: 'BV', label: 'Bra&#537;ov' },
        { value: 'B', label: 'Bucure&#537;ti' },
        { value: 'BZ', label: 'Buz&#259;u' },
        { value: 'CL', label: 'C&#259;l&#259;ra&#537;i' },
        { value: 'CS', label: 'Cara&#537;-Severin' },
        { value: 'CJ', label: 'Cluj' },
        { value: 'CT', label: 'Constan&#539;a' },
        { value: 'CV', label: 'Covasna' },
        { value: 'DB', label: 'D&acirc;mbovi&#539;a' },
        { value: 'DJ', label: 'Dolj' },
        { value: 'GL', label: 'Gala&#539;i' },
        { value: 'GR', label: 'Giurgiu' },
        { value: 'GJ', label: 'Gorj' },
        { value: 'HR', label: 'Harghita' },
        { value: 'HD', label: 'Hunedoara' },
        { value: 'IL', label: 'Ialomi&#539;a' },
        { value: 'IS', label: 'Ia&#537;i' },
        { value: 'IF', label: 'Ilfov' },
        { value: 'MM', label: 'Maramure&#537;' },
        { value: 'MH', label: 'Mehedin&#539;i' },
        { value: 'MS', label: 'Mure&#537;' },
        { value: 'NT', label: 'Neam&#539;' },
        { value: 'OT', label: 'Olt' },
        { value: 'PH', label: 'Prahova' },
        { value: 'SJ', label: 'S&#259;laj' },
        { value: 'SM', label: 'Satu Mare' },
        { value: 'SB', label: 'Sibiu' },
        { value: 'SV', label: 'Suceava' },
        { value: 'TR', label: 'Teleorman' },
        { value: 'TM', label: 'Timi&#537;' },
        { value: 'TL', label: 'Tulcea' },
        { value: 'VL', label: 'V&acirc;lcea' },
        { value: 'VS', label: 'Vaslui' },
        { value: 'VN', label: 'Vrancea' },
    ],
    'RS': [],
    'SG': [],
    'SK': [],
    'SI': [],
    'TH': [ // Thailand states.
        { value: 'TH-37', label: 'Amnat Charoen' },
        { value: 'TH-15', label: 'Ang Thong' },
        { value: 'TH-14', label: 'Ayutthaya' },
        { value: 'TH-10', label: 'Bangkok' },
        { value: 'TH-38', label: 'Bueng Kan' },
        { value: 'TH-31', label: 'Buri Ram' },
        { value: 'TH-24', label: 'Chachoengsao' },
        { value: 'TH-18', label: 'Chai Nat' },
        { value: 'TH-36', label: 'Chaiyaphum' },
        { value: 'TH-22', label: 'Chanthaburi' },
        { value: 'TH-50', label: 'Chiang Mai' },
        { value: 'TH-57', label: 'Chiang Rai' },
        { value: 'TH-20', label: 'Chonburi' },
        { value: 'TH-86', label: 'Chumphon' },
        { value: 'TH-46', label: 'Kalasin' },
        { value: 'TH-62', label: 'Kamphaeng Phet' },
        { value: 'TH-71', label: 'Kanchanaburi' },
        { value: 'TH-40', label: 'Khon Kaen' },
        { value: 'TH-81', label: 'Krabi' },
        { value: 'TH-52', label: 'Lampang' },
        { value: 'TH-51', label: 'Lamphun' },
        { value: 'TH-42', label: 'Loei' },
        { value: 'TH-16', label: 'Lopburi' },
        { value: 'TH-58', label: 'Mae Hong Son' },
        { value: 'TH-44', label: 'Maha Sarakham' },
        { value: 'TH-49', label: 'Mukdahan' },
        { value: 'TH-26', label: 'Nakhon Nayok' },
        { value: 'TH-73', label: 'Nakhon Pathom' },
        { value: 'TH-48', label: 'Nakhon Phanom' },
        { value: 'TH-30', label: 'Nakhon Ratchasima' },
        { value: 'TH-60', label: 'Nakhon Sawan' },
        { value: 'TH-80', label: 'Nakhon Si Thammarat' },
        { value: 'TH-55', label: 'Nan' },
        { value: 'TH-96', label: 'Narathiwat' },
        { value: 'TH-39', label: 'Nong Bua Lam Phu' },
        { value: 'TH-43', label: 'Nong Khai' },
        { value: 'TH-12', label: 'Nonthaburi' },
        { value: 'TH-13', label: 'Pathum Thani' },
        { value: 'TH-94', label: 'Pattani' },
        { value: 'TH-82', label: 'Phang Nga' },
        { value: 'TH-93', label: 'Phatthalung' },
        { value: 'TH-56', label: 'Phayao' },
        { value: 'TH-67', label: 'Phetchabun' },
        { value: 'TH-76', label: 'Phetchaburi' },
        { value: 'TH-66', label: 'Phichit' },
        { value: 'TH-65', label: 'Phitsanulok' },
        { value: 'TH-54', label: 'Phrae' },
        { value: 'TH-83', label: 'Phuket' },
        { value: 'TH-25', label: 'Prachin Buri' },
        { value: 'TH-77', label: 'Prachuap Khiri Khan' },
        { value: 'TH-85', label: 'Ranong' },
        { value: 'TH-70', label: 'Ratchaburi' },
        { value: 'TH-21', label: 'Rayong' },
        { value: 'TH-45', label: 'Roi Et' },
        { value: 'TH-27', label: 'Sa Kaeo' },
        { value: 'TH-47', label: 'Sakon Nakhon' },
        { value: 'TH-11', label: 'Samut Prakan' },
        { value: 'TH-74', label: 'Samut Sakhon' },
        { value: 'TH-75', label: 'Samut Songkhram' },
        { value: 'TH-19', label: 'Saraburi' },
        { value: 'TH-91', label: 'Satun' },
        { value: 'TH-17', label: 'Sing Buri' },
        { value: 'TH-33', label: 'Sisaket' },
        { value: 'TH-90', label: 'Songkhla' },
        { value: 'TH-64', label: 'Sukhothai' },
        { value: 'TH-72', label: 'Suphan Buri' },
        { value: 'TH-84', label: 'Surat Thani' },
        { value: 'TH-32', label: 'Surin' },
        { value: 'TH-63', label: 'Tak' },
        { value: 'TH-92', label: 'Trang' },
        { value: 'TH-23', label: 'Trat' },
        { value: 'TH-34', label: 'Ubon Ratchathani' },
        { value: 'TH-41', label: 'Udon Thani' },
        { value: 'TH-61', label: 'Uthai Thani' },
        { value: 'TH-53', label: 'Uttaradit' },
        { value: 'TH-95', label: 'Yala' },
        { value: 'TH-35', label: 'Yasothon' },
    ],
    'TR': [ // Turkey States.
        { value: 'TR01', label: 'Adana' },
        { value: 'TR02', label: 'Ad&#305;yaman' },
        { value: 'TR03', label: 'Afyon' },
        { value: 'TR04', label: 'A&#287;r&#305;' },
        { value: 'TR05', label: 'Amasya' },
        { value: 'TR06', label: 'Ankara' },
        { value: 'TR07', label: 'Antalya' },
        { value: 'TR08', label: 'Artvin' },
        { value: 'TR09', label: 'Ayd&#305;n' },
        { value: 'TR10', label: 'Bal&#305;kesir' },
        { value: 'TR11', label: 'Bilecik' },
        { value: 'TR12', label: 'Bing&#246;l' },
        { value: 'TR13', label: 'Bitlis' },
        { value: 'TR14', label: 'Bolu' },
        { value: 'TR15', label: 'Burdur' },
        { value: 'TR16', label: 'Bursa' },
        { value: 'TR17', label: '&#199;anakkale' },
        { value: 'TR18', label: '&#199;ank&#305;r&#305;' },
        { value: 'TR19', label: '&#199;orum' },
        { value: 'TR20', label: 'Denizli' },
        { value: 'TR21', label: 'Diyarbak&#305;r' },
        { value: 'TR22', label: 'Edirne' },
        { value: 'TR23', label: 'Elaz&#305;&#287;' },
        { value: 'TR24', label: 'Erzincan' },
        { value: 'TR25', label: 'Erzurum' },
        { value: 'TR26', label: 'Eski&#351;ehir' },
        { value: 'TR27', label: 'Gaziantep' },
        { value: 'TR28', label: 'Giresun' },
        { value: 'TR29', label: 'G&#252;m&#252;&#351;hane' },
        { value: 'TR30', label: 'Hakkari' },
        { value: 'TR31', label: 'Hatay' },
        { value: 'TR32', label: 'Isparta' },
        { value: 'TR33', label: '&#304;&#231;el' },
        { value: 'TR34', label: '&#304;stanbul' },
        { value: 'TR35', label: '&#304;zmir' },
        { value: 'TR36', label: 'Kars' },
        { value: 'TR37', label: 'Kastamonu' },
        { value: 'TR38', label: 'Kayseri' },
        { value: 'TR39', label: 'K&#305;rklareli' },
        { value: 'TR40', label: 'K&#305;r&#351;ehir' },
        { value: 'TR41', label: 'Kocaeli' },
        { value: 'TR42', label: 'Konya' },
        { value: 'TR43', label: 'K&#252;tahya' },
        { value: 'TR44', label: 'Malatya' },
        { value: 'TR45', label: 'Manisa' },
        { value: 'TR46', label: 'Kahramanmara&#351;' },
        { value: 'TR47', label: 'Mardin' },
        { value: 'TR48', label: 'Mu&#287;la' },
        { value: 'TR49', label: 'Mu&#351;' },
        { value: 'TR50', label: 'Nev&#351;ehir' },
        { value: 'TR51', label: 'Ni&#287;de' },
        { value: 'TR52', label: 'Ordu' },
        { value: 'TR53', label: 'Rize' },
        { value: 'TR54', label: 'Sakarya' },
        { value: 'TR55', label: 'Samsun' },
        { value: 'TR56', label: 'Siirt' },
        { value: 'TR57', label: 'Sinop' },
        { value: 'TR58', label: 'Sivas' },
        { value: 'TR59', label: 'Tekirda&#287;' },
        { value: 'TR60', label: 'Tokat' },
        { value: 'TR61', label: 'Trabzon' },
        { value: 'TR62', label: 'Tunceli' },
        { value: 'TR63', label: '&#350;anl&#305;urfa' },
        { value: 'TR64', label: 'U&#351;ak' },
        { value: 'TR65', label: 'Van' },
        { value: 'TR66', label: 'Yozgat' },
        { value: 'TR67', label: 'Zonguldak' },
        { value: 'TR68', label: 'Aksaray' },
        { value: 'TR69', label: 'Bayburt' },
        { value: 'TR70', label: 'Karaman' },
        { value: 'TR71', label: 'K&#305;r&#305;kkale' },
        { value: 'TR72', label: 'Batman' },
        { value: 'TR73', label: '&#350;&#305;rnak' },
        { value: 'TR74', label: 'Bart&#305;n' },
        { value: 'TR75', label: 'Ardahan' },
        { value: 'TR76', label: 'I&#287;d&#305;r' },
        { value: 'TR77', label: 'Yalova' },
        { value: 'TR78', label: 'Karab&#252;k' },
        { value: 'TR79', label: 'Kilis' },
        { value: 'TR80', label: 'Osmaniye' },
        { value: 'TR81', label: 'D&#252;zce' },
    ],
    'TZ': [ // Tanzania States.
        { value: 'TZ01', label: 'Arusha' },
        { value: 'TZ02', label: 'Dar es Salaam' },
        { value: 'TZ03', label: 'Dodoma' },
        { value: 'TZ04', label: 'Iringa' },
        { value: 'TZ05', label: 'Kagera' },
        { value: 'TZ06', label: 'Pemba North' },
        { value: 'TZ07', label: 'Zanzibar North' },
        { value: 'TZ08', label: 'Kigoma' },
        { value: 'TZ09', label: 'Kilimanjaro' },
        { value: 'TZ10', label: 'Pemba South' },
        { value: 'TZ11', label: 'Zanzibar South' },
        { value: 'TZ12', label: 'Lindi' },
        { value: 'TZ13', label: 'Mara' },
        { value: 'TZ14', label: 'Mbeya' },
        { value: 'TZ15', label: 'Zanzibar West' },
        { value: 'TZ16', label: 'Morogoro' },
        { value: 'TZ17', label: 'Mtwara' },
        { value: 'TZ18', label: 'Mwanza' },
        { value: 'TZ19', label: 'Coast' },
        { value: 'TZ20', label: 'Rukwa' },
        { value: 'TZ21', label: 'Ruvuma' },
        { value: 'TZ22', label: 'Shinyanga' },
        { value: 'TZ23', label: 'Singvaluea' },
        { value: 'TZ24', label: 'Tabora' },
        { value: 'TZ25', label: 'Tanga' },
        { value: 'TZ26', label: 'Manyara' },
        { value: 'TZ27', label: 'Geita' },
        { value: 'TZ28', label: 'Katavi' },
        { value: 'TZ29', label: 'Njombe' },
        { value: 'TZ30', label: 'Simiyu' },
    ],
    'LK': [],
    'SE': [],
    'UG': [ // Uganda districts. Ref: https://en.wikipedia.org/wiki/ISO_3166-2:UG.
        { value: 'UG314', label: 'Abim' },
        { value: 'UG301', label: 'Adjumani' },
        { value: 'UG322', label: 'Agago' },
        { value: 'UG323', label: 'Alebtong' },
        { value: 'UG315', label: 'Amolatar' },
        { value: 'UG324', label: 'Amudat' },
        { value: 'UG216', label: 'Amuria' },
        { value: 'UG316', label: 'Amuru' },
        { value: 'UG302', label: 'Apac' },
        { value: 'UG303', label: 'Arua' },
        { value: 'UG217', label: 'Budaka' },
        { value: 'UG218', label: 'Bududa' },
        { value: 'UG201', label: 'Bugiri' },
        { value: 'UG235', label: 'Bugweri' },
        { value: 'UG420', label: 'Buhweju' },
        { value: 'UG117', label: 'Buikwe' },
        { value: 'UG219', label: 'Bukedea' },
        { value: 'UG118', label: 'Bukomansimbi' },
        { value: 'UG220', label: 'Bukwa' },
        { value: 'UG225', label: 'Bulambuli' },
        { value: 'UG416', label: 'Buliisa' },
        { value: 'UG401', label: 'Bundibugyo' },
        { value: 'UG430', label: 'Bunyangabu' },
        { value: 'UG402', label: 'Bushenyi' },
        { value: 'UG202', label: 'Busia' },
        { value: 'UG221', label: 'Butaleja' },
        { value: 'UG119', label: 'Butambala' },
        { value: 'UG233', label: 'Butebo' },
        { value: 'UG120', label: 'Buvuma' },
        { value: 'UG226', label: 'Buyende' },
        { value: 'UG317', label: 'Dokolo' },
        { value: 'UG121', label: 'Gomba' },
        { value: 'UG304', label: 'Gulu' },
        { value: 'UG403', label: 'Hoima' },
        { value: 'UG417', label: 'Ibanda' },
        { value: 'UG203', label: 'Iganga' },
        { value: 'UG418', label: 'Isingiro' },
        { value: 'UG204', label: 'Jinja' },
        { value: 'UG318', label: 'Kaabong' },
        { value: 'UG404', label: 'Kabale' },
        { value: 'UG405', label: 'Kabarole' },
        { value: 'UG213', label: 'Kaberamavalueo' },
        { value: 'UG427', label: 'Kagadi' },
        { value: 'UG428', label: 'Kakumiro' },
        { value: 'UG101', label: 'Kalangala' },
        { value: 'UG222', label: 'Kaliro' },
        { value: 'UG122', label: 'Kalungu' },
        { value: 'UG102', label: 'Kampala' },
        { value: 'UG205', label: 'Kamuli' },
        { value: 'UG413', label: 'Kamwenge' },
        { value: 'UG414', label: 'Kanungu' },
        { value: 'UG206', label: 'Kapchorwa' },
        { value: 'UG236', label: 'Kapelebyong' },
        { value: 'UG126', label: 'Kasanda' },
        { value: 'UG406', label: 'Kasese' },
        { value: 'UG207', label: 'Katakwi' },
        { value: 'UG112', label: 'Kayunga' },
        { value: 'UG407', label: 'Kibaale' },
        { value: 'UG103', label: 'Kiboga' },
        { value: 'UG227', label: 'Kibuku' },
        { value: 'UG432', label: 'Kikuube' },
        { value: 'UG419', label: 'Kiruhura' },
        { value: 'UG421', label: 'Kiryandongo' },
        { value: 'UG408', label: 'Kisoro' },
        { value: 'UG305', label: 'Kitgum' },
        { value: 'UG319', label: 'Koboko' },
        { value: 'UG325', label: 'Kole' },
        { value: 'UG306', label: 'Kotvalueo' },
        { value: 'UG208', label: 'Kumi' },
        { value: 'UG333', label: 'Kwania' },
        { value: 'UG228', label: 'Kween' },
        { value: 'UG123', label: 'Kyankwanzi' },
        { value: 'UG422', label: 'Kyegegwa' },
        { value: 'UG415', label: 'Kyenjojo' },
        { value: 'UG125', label: 'Kyotera' },
        { value: 'UG326', label: 'Lamwo' },
        { value: 'UG307', label: 'Lira' },
        { value: 'UG229', label: 'Luuka' },
        { value: 'UG104', label: 'Luwero' },
        { value: 'UG124', label: 'Lwengo' },
        { value: 'UG114', label: 'Lyantonde' },
        { value: 'UG223', label: 'Manafwa' },
        { value: 'UG320', label: 'Maracha' },
        { value: 'UG105', label: 'Masaka' },
        { value: 'UG409', label: 'Masindi' },
        { value: 'UG214', label: 'Mayuge' },
        { value: 'UG209', label: 'Mbale' },
        { value: 'UG410', label: 'Mbarara' },
        { value: 'UG423', label: 'Mitooma' },
        { value: 'UG115', label: 'Mityana' },
        { value: 'UG308', label: 'Moroto' },
        { value: 'UG309', label: 'Moyo' },
        { value: 'UG106', label: 'Mpigi' },
        { value: 'UG107', label: 'Mubende' },
        { value: 'UG108', label: 'Mukono' },
        { value: 'UG334', label: 'Nabilatuk' },
        { value: 'UG311', label: 'Nakapiripirit' },
        { value: 'UG116', label: 'Nakaseke' },
        { value: 'UG109', label: 'Nakasongola' },
        { value: 'UG230', label: 'Namayingo' },
        { value: 'UG234', label: 'Namisindwa' },
        { value: 'UG224', label: 'Namutumba' },
        { value: 'UG327', label: 'Napak' },
        { value: 'UG310', label: 'Nebbi' },
        { value: 'UG231', label: 'Ngora' },
        { value: 'UG424', label: 'Ntoroko' },
        { value: 'UG411', label: 'Ntungamo' },
        { value: 'UG328', label: 'Nwoya' },
        { value: 'UG331', label: 'Omoro' },
        { value: 'UG329', label: 'Otuke' },
        { value: 'UG321', label: 'Oyam' },
        { value: 'UG312', label: 'Pader' },
        { value: 'UG332', label: 'Pakwach' },
        { value: 'UG210', label: 'Pallisa' },
        { value: 'UG110', label: 'Rakai' },
        { value: 'UG429', label: 'Rubanda' },
        { value: 'UG425', label: 'Rubirizi' },
        { value: 'UG431', label: 'Rukiga' },
        { value: 'UG412', label: 'Rukungiri' },
        { value: 'UG111', label: 'Sembabule' },
        { value: 'UG232', label: 'Serere' },
        { value: 'UG426', label: 'Sheema' },
        { value: 'UG215', label: 'Sironko' },
        { value: 'UG211', label: 'Soroti' },
        { value: 'UG212', label: 'Tororo' },
        { value: 'UG113', label: 'Wakiso' },
        { value: 'UG313', label: 'Yumbe' },
        { value: 'UG330', label: 'Zombo' },
    ],
    'UM': [
        { value: '81', label: 'Baker Island' },
        { value: '84', label: 'Howland Island' },
        { value: '86', label: 'Jarvis Island' },
        { value: '67', label: 'Johnston Atoll' },
        { value: '89', label: 'Kingman Reef' },
        { value: '71', label: 'Mvalueway Atoll' },
        { value: '76', label: 'Navassa Island' },
        { value: '95', label: 'Palmyra Atoll' },
        { value: '79', label: 'Wake Island' },
    ],
    'US': [ // United States.
        { value: 'AL', label: 'Alabama' },
        { value: 'AK', label: 'Alaska' },
        { value: 'AZ', label: 'Arizona' },
        { value: 'AR', label: 'Arkansas' },
        { value: 'CA', label: 'California' },
        { value: 'CO', label: 'Colorado' },
        { value: 'CT', label: 'Connecticut' },
        { value: 'DE', label: 'Delaware' },
        { value: 'DC', label: 'District Of Columbia' },
        { value: 'FL', label: 'Florvaluea' },
        { value: 'GA', label: 'Georgia' },
        { value: 'HI', label: 'Hawaii' },
        { value: 'ID', label: 'Idaho' },
        { value: 'IL', label: 'Illinois' },
        { value: 'IN', label: 'Indiana' },
        { value: 'IA', label: 'Iowa' },
        { value: 'KS', label: 'Kansas' },
        { value: 'KY', label: 'Kentucky' },
        { value: 'LA', label: 'Louisiana' },
        { value: 'ME', label: 'Maine' },
        { value: 'MD', label: 'Maryland' },
        { value: 'MA', label: 'Massachusetts' },
        { value: 'MI', label: 'Michigan' },
        { value: 'MN', label: 'Minnesota' },
        { value: 'MS', label: 'Mississippi' },
        { value: 'MO', label: 'Missouri' },
        { value: 'MT', label: 'Montana' },
        { value: 'NE', label: 'Nebraska' },
        { value: 'NV', label: 'Nevada' },
        { value: 'NH', label: 'New Hampshire' },
        { value: 'NJ', label: 'New Jersey' },
        { value: 'NM', label: 'New Mexico' },
        { value: 'NY', label: 'New York' },
        { value: 'NC', label: 'North Carolina' },
        { value: 'ND', label: 'North Dakota' },
        { value: 'OH', label: 'Ohio' },
        { value: 'OK', label: 'Oklahoma' },
        { value: 'OR', label: 'Oregon' },
        { value: 'PA', label: 'Pennsylvania' },
        { value: 'RI', label: 'Rhode Island' },
        { value: 'SC', label: 'South Carolina' },
        { value: 'SD', label: 'South Dakota' },
        { value: 'TN', label: 'Tennessee' },
        { value: 'TX', label: 'Texas' },
        { value: 'UT', label: 'Utah' },
        { value: 'VT', label: 'Vermont' },
        { value: 'VA', label: 'Virginia' },
        { value: 'WA', label: 'Washington' },
        { value: 'WV', label: 'West Virginia' },
        { value: 'WI', label: 'Wisconsin' },
        { value: 'WY', label: 'Wyoming' },
        { value: 'AA', label: 'Armed Forces (AA)' },
        { value: 'AE', label: 'Armed Forces (AE)' },
        { value: 'AP', label: 'Armed Forces (AP)' },
    ],
    'VN': [],
    'YT': [],
    'ZA': [ // South African states.
        { value: 'EC', label: 'Eastern Cape' },
        { value: 'FS', label: 'Free State' },
        { value: 'GP', label: 'Gauteng' },
        { value: 'KZN', label: 'KwaZulu-Natal' },
        { value: 'LP', label: 'Limpopo' },
        { value: 'MP', label: 'Mpumalanga' },
        { value: 'NC', label: 'Northern Cape' },
        { value: 'NW', label: 'North West' },
        { value: 'WC', label: 'Western Cape' },
    ],
    'ZM': [ // Zambia's Provinces. Ref: https://en.wikipedia.org/wiki/ISO_3166-2:ZM.
        { value: 'ZM-01', label: 'Western' },
        { value: 'ZM-02', label: 'Central' },
        { value: 'ZM-03', label: 'Eastern' },
        { value: 'ZM-04', label: 'Luapula' },
        { value: 'ZM-05', label: 'Northern' },
        { value: 'ZM-06', label: 'North-Western' },
        { value: 'ZM-07', label: 'Southern' },
        { value: 'ZM-08', label: 'Copperbelt' },
        { value: 'ZM-09', label: 'Lusaka' },
        { value: 'ZM-10', label: 'Muchinga' },
    ],
};

export const CURRENCY_SYMBOLS = {
    'AED': '&#x62f;.&#x625;',
    'AFN': '&#x60b;',
    'ALL': 'L',
    'AMD': 'AMD',
    'ANG': '&fnof;',
    'AOA': 'Kz',
    'ARS': '&#36;',
    'AUD': '&#36;',
    'AWG': 'Afl.',
    'AZN': 'AZN',
    'BAM': 'KM',
    'BBD': '&#36;',
    'BDT': '&#2547;&nbsp;',
    'BGN': '&#1083;&#1074;.',
    'BHD': '.&#x62f;.&#x628;',
    'BIF': 'Fr',
    'BMD': '&#36;',
    'BND': '&#36;',
    'BOB': 'Bs.',
    'BRL': '&#82;&#36;',
    'BSD': '&#36;',
    'BTC': '&#3647;',
    'BTN': 'Nu.',
    'BWP': 'P',
    'BYR': 'Br',
    'BYN': 'Br',
    'BZD': '&#36;',
    'CAD': '&#36;',
    'CDF': 'Fr',
    'CHF': '&#67;&#72;&#70;',
    'CLP': '&#36;',
    'CNY': '&yen;',
    'COP': '&#36;',
    'CRC': '&#x20a1;',
    'CUC': '&#36;',
    'CUP': '&#36;',
    'CVE': '&#36;',
    'CZK': '&#75;&#269;',
    'DJF': 'Fr',
    'DKK': 'DKK',
    'DOP': 'RD&#36;',
    'DZD': '&#x62f;.&#x62c;',
    'EGP': 'EGP',
    'ERN': 'Nfk',
    'ETB': 'Br',
    'EUR': '&euro;',
    'FJD': '&#36;',
    'FKP': '&pound;',
    'GBP': '&pound;',
    'GEL': '&#x20be;',
    'GGP': '&pound;',
    'GHS': '&#x20b5;',
    'GIP': '&pound;',
    'GMD': 'D',
    'GNF': 'Fr',
    'GTQ': 'Q',
    'GYD': '&#36;',
    'HKD': '&#36;',
    'HNL': 'L',
    'HRK': 'kn',
    'HTG': 'G',
    'HUF': '&#70;&#116;',
    'IDR': 'Rp',
    'ILS': '&#8362;',
    'IMP': '&pound;',
    'INR': '&#8377;',
    'IQD': '&#x639;.&#x62f;',
    'IRR': '&#xfdfc;',
    'IRT': '&#x062A;&#x0648;&#x0645;&#x0627;&#x0646;',
    'ISK': 'kr.',
    'JEP': '&pound;',
    'JMD': '&#36;',
    'JOD': '&#x62f;.&#x627;',
    'JPY': '&yen;',
    'KES': 'KSh',
    'KGS': '&#x441;&#x43e;&#x43c;',
    'KHR': '&#x17db;',
    'KMF': 'Fr',
    'KPW': '&#x20a9;',
    'KRW': '&#8361;',
    'KWD': '&#x62f;.&#x643;',
    'KYD': '&#36;',
    'KZT': 'KZT',
    'LAK': '&#8365;',
    'LBP': '&#x644;.&#x644;',
    'LKR': '&#xdbb;&#xdd4;',
    'LRD': '&#36;',
    'LSL': 'L',
    'LYD': '&#x644;.&#x62f;',
    'MAD': '&#x62f;.&#x645;.',
    'MDL': 'MDL',
    'MGA': 'Ar',
    'MKD': '&#x434;&#x435;&#x43d;',
    'MMK': 'Ks',
    'MNT': '&#x20ae;',
    'MOP': 'P',
    'MRU': 'UM',
    'MUR': '&#x20a8;',
    'MVR': '.&#x783;',
    'MWK': 'MK',
    'MXN': '&#36;',
    'MYR': '&#82;&#77;',
    'MZN': 'MT',
    'NAD': 'N&#36;',
    'NGN': '&#8358;',
    'NIO': 'C&#36;',
    'NOK': '&#107;&#114;',
    'NPR': '&#8360;',
    'NZD': '&#36;',
    'OMR': '&#x631;.&#x639;.',
    'PAB': 'B/.',
    'PEN': 'S/',
    'PGK': 'K',
    'PHP': '&#8369;',
    'PKR': '&#8360;',
    'PLN': '&#122;&#322;',
    'PRB': '&#x440;.',
    'PYG': '&#8370;',
    'QAR': '&#x631;.&#x642;',
    'RMB': '&yen;',
    'RON': 'lei',
    'RSD': '&#x434;&#x438;&#x43d;.',
    'RUB': '&#8381;',
    'RWF': 'Fr',
    'SAR': '&#x631;.&#x633;',
    'SBD': '&#36;',
    'SCR': '&#x20a8;',
    'SDG': '&#x62c;.&#x633;.',
    'SEK': '&#107;&#114;',
    'SGD': '&#36;',
    'SHP': '&pound;',
    'SLL': 'Le',
    'SOS': 'Sh',
    'SRD': '&#36;',
    'SSP': '&pound;',
    'STN': 'Db',
    'SYP': '&#x644;.&#x633;',
    'SZL': 'L',
    'THB': '&#3647;',
    'TJS': '&#x405;&#x41c;',
    'TMT': 'm',
    'TND': '&#x62f;.&#x62a;',
    'TOP': 'T&#36;',
    'TRY': '&#8378;',
    'TTD': '&#36;',
    'TWD': '&#78;&#84;&#36;',
    'TZS': 'Sh',
    'UAH': '&#8372;',
    'UGX': 'UGX',
    'USD': '&#36;',
    'UYU': '&#36;',
    'UZS': 'UZS',
    'VEF': 'Bs F',
    'VES': 'Bs.S',
    'VND': '&#8363;',
    'VUV': 'Vt',
    'WST': 'T',
    'XAF': 'CFA',
    'XCD': '&#36;',
    'XOF': 'CFA',
    'XPF': 'Fr',
    'YER': '&#xfdfc;',
    'ZAR': '&#82;',
    'ZMW': 'ZK'
};

export const CURRENCIES = {
    'AED': 'United Arab Emirates dirham',
    'AFN': 'Afghan afghani',
    'ALL': 'Albanian lek',
    'AMD': 'Armenian dram',
    'ANG': 'Netherlands Antillean guilder',
    'AOA': 'Angolan kwanza',
    'ARS': 'Argentine peso',
    'AUD': 'Australian dollar',
    'AWG': 'Aruban florin',
    'AZN': 'Azerbaijani manat',
    'BAM': 'Bosnia and Herzegovina convertible mark',
    'BBD': 'Barbadian dollar',
    'BDT': 'Bangladeshi taka',
    'BGN': 'Bulgarian lev',
    'BHD': 'Bahraini dinar',
    'BIF': 'Burundian franc',
    'BMD': 'Bermudian dollar',
    'BND': 'Brunei dollar',
    'BOB': 'Bolivian boliviano',
    'BRL': 'Brazilian real',
    'BSD': 'Bahamian dollar',
    'BTC': 'Bitcoin',
    'BTN': 'Bhutanese ngultrum',
    'BWP': 'Botswana pula',
    'BYR': 'Belarusian ruble (old)',
    'BYN': 'Belarusian ruble',
    'BZD': 'Belize dollar',
    'CAD': 'Canadian dollar',
    'CDF': 'Congolese franc',
    'CHF': 'Swiss franc',
    'CLP': 'Chilean peso',
    'CNY': 'Chinese yuan',
    'COP': 'Colombian peso',
    'CRC': 'Costa Rican col&oacute;n',
    'CUC': 'Cuban convertible peso',
    'CUP': 'Cuban peso',
    'CVE': 'Cape Verdean escudo',
    'CZK': 'Czech koruna',
    'DJF': 'Djiboutian franc',
    'DKK': 'Danish krone',
    'DOP': 'Dominican peso',
    'DZD': 'Algerian dinar',
    'EGP': 'Egyptian pound',
    'ERN': 'Eritrean nakfa',
    'ETB': 'Ethiopian birr',
    'EUR': 'Euro',
    'FJD': 'Fijian dollar',
    'FKP': 'Falkland Islands pound',
    'GBP': 'Pound sterling',
    'GEL': 'Georgian lari',
    'GGP': 'Guernsey pound',
    'GHS': 'Ghana cedi',
    'GIP': 'Gibraltar pound',
    'GMD': 'Gambian dalasi',
    'GNF': 'Guinean franc',
    'GTQ': 'Guatemalan quetzal',
    'GYD': 'Guyanese dollar',
    'HKD': 'Hong Kong dollar',
    'HNL': 'Honduran lempira',
    'HRK': 'Croatian kuna',
    'HTG': 'Haitian gourde',
    'HUF': 'Hungarian forint',
    'IDR': 'Indonesian rupiah',
    'ILS': 'Israeli new shekel',
    'IMP': 'Manx pound',
    'INR': 'Indian rupee',
    'IQD': 'Iraqi dinar',
    'IRR': 'Iranian rial',
    'IRT': 'Iranian toman',
    'ISK': 'Icelandic kr&oacute;na',
    'JEP': 'Jersey pound',
    'JMD': 'Jamaican dollar',
    'JOD': 'Jordanian dinar',
    'JPY': 'Japanese yen',
    'KES': 'Kenyan shilling',
    'KGS': 'Kyrgyzstani som',
    'KHR': 'Cambodian riel',
    'KMF': 'Comorian franc',
    'KPW': 'North Korean won',
    'KRW': 'South Korean won',
    'KWD': 'Kuwaiti dinar',
    'KYD': 'Cayman Islands dollar',
    'KZT': 'Kazakhstani tenge',
    'LAK': 'Lao kip',
    'LBP': 'Lebanese pound',
    'LKR': 'Sri Lankan rupee',
    'LRD': 'Liberian dollar',
    'LSL': 'Lesotho loti',
    'LYD': 'Libyan dinar',
    'MAD': 'Moroccan dirham',
    'MDL': 'Moldovan leu',
    'MGA': 'Malagasy ariary',
    'MKD': 'Macedonian denar',
    'MMK': 'Burmese kyat',
    'MNT': 'Mongolian t&ouml;gr&ouml;g',
    'MOP': 'Macanese pataca',
    'MRU': 'Mauritanian ouguiya',
    'MUR': 'Mauritian rupee',
    'MVR': 'Maldivian rufiyaa',
    'MWK': 'Malawian kwacha',
    'MXN': 'Mexican peso',
    'MYR': 'Malaysian ringgit',
    'MZN': 'Mozambican metical',
    'NAD': 'Namibian dollar',
    'NGN': 'Nigerian naira',
    'NIO': 'Nicaraguan c&oacute;rdoba',
    'NOK': 'Norwegian krone',
    'NPR': 'Nepalese rupee',
    'NZD': 'New Zealand dollar',
    'OMR': 'Omani rial',
    'PAB': 'Panamanian balboa',
    'PEN': 'Sol',
    'PGK': 'Papua New Guinean kina',
    'PHP': 'Philippine peso',
    'PKR': 'Pakistani rupee',
    'PLN': 'Polish z&#x142;oty',
    'PRB': 'Transnistrian ruble',
    'PYG': 'Paraguayan guaran&iacute;',
    'QAR': 'Qatari riyal',
    'RON': 'Romanian leu',
    'RSD': 'Serbian dinar',
    'RUB': 'Russian ruble',
    'RWF': 'Rwandan franc',
    'SAR': 'Saudi riyal',
    'SBD': 'Solomon Islands dollar',
    'SCR': 'Seychellois rupee',
    'SDG': 'Sudanese pound',
    'SEK': 'Swedish krona',
    'SGD': 'Singapore dollar',
    'SHP': 'Saint Helena pound',
    'SLL': 'Sierra Leonean leone',
    'SOS': 'Somali shilling',
    'SRD': 'Surinamese dollar',
    'SSP': 'South Sudanese pound',
    'STN': 'S&atilde;o Tom&eacute; and Pr&iacute;ncipe dobra',
    'SYP': 'Syrian pound',
    'SZL': 'Swazi lilangeni',
    'THB': 'Thai baht',
    'TJS': 'Tajikistani somoni',
    'TMT': 'Turkmenistan manat',
    'TND': 'Tunisian dinar',
    'TOP': 'Tongan pa&#x2bb;anga',
    'TRY': 'Turkish lira',
    'TTD': 'Trinidad and Tobago dollar',
    'TWD': 'New Taiwan dollar',
    'TZS': 'Tanzanian shilling',
    'UAH': 'Ukrainian hryvnia',
    'UGX': 'Ugandan shilling',
    'USD': 'United States (US) dollar',
    'UYU': 'Uruguayan peso',
    'UZS': 'Uzbekistani som',
    'VEF': 'Venezuelan bol&iacute;var',
    'VES': 'Bol&iacute;var soberano',
    'VND': 'Vietnamese &#x111;&#x1ed3;ng',
    'VUV': 'Vanuatu vatu',
    'WST': 'Samoan t&#x101;l&#x101;',
    'XAF': 'Central African CFA franc',
    'XCD': 'East Caribbean dollar',
    'XOF': 'West African CFA franc',
    'XPF': 'CFP franc',
    'YER': 'Yemeni rial',
    'ZAR': 'South African rand',
    'ZMW': 'Zambian kwacha'
};

export const WEIGHT_UNITS = [
    "kg",
    "g",
    "lbs",
    "oz"
];

export const DIMENSIONS_UNITS = [
    "m",
    "cm",
    "mm",
    "inch",
    "yd"
];
