import axios from "axios";
import { useContext, useReducer } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import reducer, { actionTypes } from "../reducer/reducerProfile";

const AppContext = createContext();

const getProfileData = () => {
  var profileData = localStorage.getItem("tele-admin-user-profile");

  if (
    typeof profileData === "undefined" ||
    profileData === "undefined" ||
    profileData === null ||
    profileData.length === 0
  ) {
    return [];
  } else {
    return JSON.parse(profileData);
  }
};
const isLoggedin = () => {
  var isLoggined = localStorage.getItem("tele-admin-loggedin");

  if (
    typeof isLoggined === "undefined" ||
    isLoggined == "undefined" ||
    isLoggined == null ||
    isLoggined == "null" ||
    isLoggined === "false"
  ) {
    return false;
  } else {
    return true;
  }
};

const initialState = {
  isLoading: false,
  isError: false,
  errorMessage: "",
  isLogin: isLoggedin(),
  userProfile: getProfileData(),
  total_product: 0,
  total_order_count: 0,
  total_order: [],
};
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem("tele-admin-loggedin", state.isLogin);
    localStorage.setItem(
      "tele-admin-user-profile",
      JSON.stringify(state.userProfile)
    );
  }, [state.userProfile]);

  useEffect(() => {
    productCount();
  }, [state.total_product]);

  useEffect(() => {
    totalOrder();
  }, []);

  const checkLogin = () => {
    if (isLoggedin() === false) {
      window.location.href = "/dashboard-login";
      return false;
    }
  };

  const AuthCheckerHandler = async (login_data) => {
    dispatch({ type: actionTypes.isLoading });
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "admin/login",
        login_data
      );
      const res = await result.data;
      const data = res.data;
      dispatch({ type: actionTypes.SetUserProfile, payload: data });
    } catch (error) {
      dispatch({
        type: actionTypes.isError,
        payload: error.response.data.message,
      });
    }
  };

  const SignOutHandler = async () => {
    dispatch({ type: actionTypes.isLoading });
    try {
      dispatch({ type: actionTypes.setLogOut });
    } catch (error) {
      dispatch({ type: actionTypes.isError });
    }
  };

  const productCount = async () => {
    dispatch({ type: actionTypes.isLoading });
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + "productCount"
      );
      const res = await result.data;
      const data = res.data;
      dispatch({ type: actionTypes.ProductCount, payload: data });
    } catch (error) {
      dispatch({
        type: actionTypes.isError,
        payload: error.response.data.message,
      });
    }
  };

  const totalOrder = async () => {
    dispatch({ type: actionTypes.isLoading });
    try {
      const result = await axios.get(
        process.env.REACT_APP_API_URL + "get_all_order_list"
      );
      const res = await result.data;
      const data = res.data;
      dispatch({ type: actionTypes.Total_order, payload: data });
    } catch (error) {
      dispatch({
        type: actionTypes.isError,
        payload: error.response.data.message,
      });
    }
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        checkLogin,
        AuthCheckerHandler,
        SignOutHandler,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAapContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext, useAapContext };
