export const actionTypes = {
  SetUserProfile: "SET_USER_PROFILE_DATA",
  ProductCount: "SET_PRODUCT_COUNT",
  Total_order: "SET_TOTAL_ORDER",
  isError: "API_ERROR",
  isLoading: "SET_LOADING",
  UpdateCart: "UPDATE_CART",
  PaymentMethods: "PAYMENT_METHODS",
  API_ERROR: "API_ERROR",
  setLogOut: "SET_LOG_OUT",
};

const initialState = {
  isLoading: false,
  isError: false,
  isLogin: false,
  userProfile: {},
  total_product: 0,
  total_order_count: 0,
  total_order: [],
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.isLoading:
      return {
        ...state,
        isLoading: true,
      };

    case actionTypes.SetUserProfile:
      return {
        ...state,
        isLoading: false,
        isLogin: true,
        userProfile: action.payload,
      };
    case actionTypes.API_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case actionTypes.setLogOut:
      return {
        ...state,
        isLoading: false,
        isLogin: false,
        userProfile: {},
      };
    case actionTypes.ProductCount:
      return {
        ...state,
        isLoading: false,
        total_product: action.payload,
      };
    case actionTypes.Total_order:
      return {
        ...state,
        isLoading: false,
        total_order: action.payload,
        total_order_count: action.payload.length,
      };

    default:
      return state;
  }
};

export default profileReducer;
