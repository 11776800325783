import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";

import Breadcrumb from "../../common/breadcrumb";
import DeleteConfirmModal from "../../features/modals/delete-confirm-modal";
import Loader from "../../features/loader";
import MediaGalleryModal from "../../features/modals/media-gallery-modal";
import PNotify from "../../features/elements/p-notify";

import { getCroppedImageUrl, removeXSSAttacks } from "../../../utils";
import { getCategory } from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router";

const CategoriesEdit = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();
  const { id } = useParams();

  const [cat, setCat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tree, setTree] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalGallery, setModalGallery] = useState(false);
  const [error, setError] = useState("");
  const [media, setMedia] = useState(null);
  useEffect(() => {
    setLoading(true);
    getCategory(parseInt(id)).then((result) => {
      if (!result) {
        return props.history.push(`/pages/404`);
      }
      setCat(result.data);
      setTree(result.tree);
      setLoading(false);
    });
  }, [id]);

  function saveCategory(e) {
    e.preventDefault();
    let updateCat = {
      id: id,
      name: cat.name,
      slug: cat.slug,
      parent: cat.parent,
      media: media !== null ? media.id : 0,
      description: cat.desc,
      // count: 0,
      // depth: index >= 0 ? ajax.data[index].depth + 1 : 0,
    };
    axios
      .put(API_URL + "category_edit/" + id, updateCat)
      .then((response) => {
        let results = response.data;
        if (typeof results.errors !== "undefined") {
          setError(results.errors);
        }
        if (results.status === 200) {
          toast(
            <PNotify
              title="Success"
              icon="fas fa-check"
              text="Category saved successfully."
            />,
            {
              containerId: "default",
              className: "notification-success",
            }
          );
        }
      })
      .catch((error) => console.log(error));
  }

  function deleteCategory(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  function deleteConfirm(result) {
    axios
      .delete(API_URL + "category_delete/" + id)
      .then((response) => {
        let results = response.data;
        if (results.status === 200) {
          navigate("/categories");
        }
      })
      .catch((error) => console.log(error));
  }

  function selectImage(e) {
    e.preventDefault();
    setModalGallery(true);
  }

  function closeModal(selectedMedia) {
    setModalGallery(false);

    if (selectedMedia.length) {
      setMedia(selectedMedia[0]);
      setCat({
        ...cat,
        media: selectedMedia[0],
      });
    }
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current="Edit Category"
            paths={[
              {
                name: "Home",
                url: "/",
              },
              {
                name: "Categories",
                url: `/${cat.type}s/categories`,
              },
            ]}
          />

          <Form
            className="ecommerce-form"
            action="#"
            method="post"
            onSubmit={saveCategory}
          >
            <Row>
              <Col>
                <Card className="card-modern card-big-info">
                  <Card.Body>
                    <Row>
                      <Col lg="2-5" xl="1-5">
                        <i className="card-big-info-icon bx bx-slider"></i>
                        <h2 className="card-big-info-title">
                          Category Details
                        </h2>
                        <p className="card-big-info-desc">
                          Add here the category description with all details and
                          necessary information.
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Category Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="text"
                              className="form-control-modern"
                              maxLength="50"
                              name="name"
                              value={cat.name}
                              onChange={(e) =>
                                setCat({ ...cat, name: e.target.value })
                              }
                              required
                            />
                            <span className="help-block text-danger ">
                              {error["name"]}
                            </span>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Slug
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="text"
                              className="form-control-modern"
                              maxLength="50"
                              name="slug"
                              value={cat.slug}
                              onChange={(e) =>
                                setCat({ ...cat, slug: e.target.value })
                              }
                            />
                            <span className="help-block text-danger ">
                              {error["slug"]}
                            </span>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Parent Category
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              as="select"
                              className="form-control-modern"
                              name="parent"
                              value={cat.parent}
                              onChange={(e) =>
                                setCat({
                                  ...cat,
                                  parent: parseInt(e.target.value),
                                })
                              }
                            >
                              <option value="0">None</option>
                              {tree.map((item, index) => (
                                <option
                                  selected={item.id == cat.parent}
                                  key={"cat-" + index}
                                  value={item.id}
                                  dangerouslySetInnerHTML={removeXSSAttacks(
                                    "&nbsp;".repeat(item.depth * 3) + item.name
                                  )}
                                ></option>
                              ))}
                              <span className="help-block text-danger ">
                                {error["parent"]}
                              </span>
                            </Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0 pt-2 mt-1 mb-0"
                          >
                            Description
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              as="textarea"
                              className="form-control-modern"
                              name="description"
                              rows="6"
                              maxLength="200"
                              value={cat.description ? cat.description : ""}
                              onChange={(e) =>
                                setCat({ ...cat, description: e.target.value })
                              }
                            />
                            <span className="help-block text-danger ">
                              {error["description"]}
                            </span>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Category Image
                          </Col>
                          <Col lg={7} xl={6}>
                            <Button
                              href="#mediaGallery"
                              className="ml-auto mr-3"
                              variant="primary"
                              onClick={selectImage}
                            >
                              Select Image
                            </Button>

                            <div className="category-image d-inline-block">
                              {cat.media ? (
                                <img
                                  src={
                                    cat.media.virtual
                                      ? cat.media.copy_link
                                      : getCroppedImageUrl(
                                          `${cat.media.url}`,
                                          150
                                        )
                                  }
                                  alt={
                                    cat.media.alt_text
                                      ? cat.media.alt_text
                                      : "category"
                                  }
                                  width={60}
                                  height={60}
                                />
                              ) : (
                                <img
                                  src={`${process.env.PUBLIC_URL}/assets/images/porto-placeholder-66x66.png`}
                                  alt="category"
                                  width="60"
                                  height="60"
                                />
                              )}
                            </div>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row className="action-buttons">
              <Col md="auto" className="col-6">
                <Button
                  type="submit"
                  className="btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1"
                  variant="primary"
                >
                  <i className="bx bx-save text-4 mr-2"></i> Save Category
                </Button>
              </Col>
              <Col md="auto" className="col-6 px-md-0 mt-0">
                <Button
                  as={Link}
                  to={`/categories`}
                  className="btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center"
                  variant="light"
                >
                  Back
                </Button>
              </Col>
              <Col md="auto" className="col-6 ml-md-auto mt-3 mt-md-0">
                <Button
                  href="#delete"
                  className="btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1"
                  variant="danger"
                  onClick={deleteCategory}
                >
                  <i className="bx bx-trash text-4 mr-2"></i> Delete Category
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <DeleteConfirmModal
        isOpen={openModal}
        onClose={deleteConfirm}
        callBack={deleteConfirm}
      />

      <MediaGalleryModal
        chooseOne={true}
        isOpen={modalGallery}
        onClose={closeModal}
      />
    </>
  );
};

export default CategoriesEdit;
