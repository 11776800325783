import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Layout/layout";

import Dashboard from "./components/pages/dashboard";
import MediaGrid from "./components/pages/media/media-grid";
import MediaList from "./components/pages/media/media-list";
import MediaAdd from "./components/pages/media/media-add";
import MediaDetail from "./components/pages/media/media-detail";
import SignIn from "./components/pages/login";
import ProductList from "./components/pages/products/product-list";
import ProductAdd from "./components/pages/products/product-add";
import OrdersReport from "./components/pages/ecommerce/reports/orders-report";
import CouponList from "./components/pages/ecommerce/coupons/coupon-list";
import CouponAdd from "./components/pages/ecommerce/coupons/coupon-add";
import CategoriesList from "./components/pages/categories/categories-list";
import CategoriesEdit from "./components/pages/categories/categories-edit";
import VendorList from "./components/pages/vendor/vendor-list";
import VendorAdd from "./components/pages/vendor/vendor-add";
import ProductDetail from "./components/pages/products/product-detail";
import VendorEdit from "./components/pages/vendor/vendor-edit";
import OrderList from "./components/pages/orders/order-list";
import OrderDetail from "./components/pages/orders/order-detail";
import UserList from "./components/pages/users/user-list";
import UserCreate from "./components/pages/users/user-create";
import UserDetail from "./components/pages/users/user-detail";
import SliderList from "./components/pages/slider/slider-list";
import SliderGrid from "./components/pages/slider/slider-grid";
import SliderAdd from "./components/pages/slider/slider-add";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/dashboard-login" index element={<SignIn />} />
        <Route element={<Layout />}>
          <Route exact path="/" index element={<Dashboard />} />
          <Route exact path={`/media`} element={<MediaGrid />} />
          <Route exact path={`/media/list`} element={<MediaList />} />
          <Route exact path={`/media/create`} element={<MediaAdd />} />
          <Route exact path={`/media/:id`} element={<MediaDetail />} />
          <Route path={`/products`} element={<ProductList />} />
          <Route path={`/product/create`} element={<ProductAdd />} />
          <Route path={`/product/edit/:id`} element={<ProductDetail />} />

          <Route path={`/categories`} element={<CategoriesList />} />
          <Route exact path={`/categories/:id`} element={<CategoriesEdit />} />

          <Route path={`/ecommerce/coupons`} element={<CouponList />} />
          <Route path={`/ecommerce/coupons/create`} element={<CouponAdd />} />

          <Route
            exact
            path={`/ecommerce/reports/orders`}
            element={<OrdersReport />}
          />

          <Route exact path={`/vendor`} element={<VendorList />} />
          <Route exact path={`/vendor/create`} element={<VendorAdd />} />
          <Route exact path={`/vendor/edit/:id`} element={<VendorEdit />} />

          <Route exact path={`/users`} element={<UserList />} />
          <Route exact path={`/user/create`} element={<UserCreate />} />
          <Route exact path={`/user/edit/:id`} element={<UserDetail />} />



          <Route exact path={`/orders`} element={<OrderList/>} />
          <Route exact path={`/order/:id`} element={<OrderDetail />} /> 

          <Route exact path={`/slider`} element={<SliderGrid />} /> 
          <Route exact path={`/slider/list`} element={<SliderList />} /> 
          <Route exact path={`/slider/create`} element={<SliderAdd />} />
          
          {/* {/* <Route exact path={`/vendor/create`} element={<VendorAdd />} /> */}
          

          {/* 
          <Route
            exact
            path={`/media/settings`}
            component={MediaSetting}
          />
          
          */}
          {/* 
         
          <Route
            path={`/posts`}
            component={PostsPages}
          />
          <Route
            exact
            path={`/tags/:id`}
            component={TagsEdit}
          />
         
          <Route
            path={`/ecommerce`}
            component={EcommercePages}
          />
          <Route
            path={`/multivendor`}
            component={MultivendorPages}
          />
         */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;

