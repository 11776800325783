import React, { useState } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";

import Breadcrumb from "../../common/breadcrumb";
import PtDropzone from "../../features/elements/dropzone";
import { uploadMedia } from "../../../api";
import axios from "axios";
import { useNavigate } from "react-router";
const SliderAdd = () => {
  let navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedFiles, setSelectedFiles] = useState([]);

  const filesUpload = (e) => {
    selectedFiles[0]["media_type"] = 2;
    setSelectedFiles([...selectedFiles, ["media_type", 2]]);
    
    const json = {
      media: selectedFiles,
    };
    return axios.post(API_URL + "upload_media", json).then((response) => {
      if (response.data.status === 200) {
        navigate("/slider");
      }
    });
  };

  return (
    <>
      <Breadcrumb
        current="Add new Slider"
        paths={[
          { name: "Home", url: "/" },
          { name: "Slider", url: "/slider" },
        ]}
      />
      <Row>
        <Col>
          <Card className="card-modern">
            <Card.Body>
              <Row>
                <Col>
                  <PtDropzone
                    selectedFiles={(files) => setSelectedFiles(files)}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* <Col>
          <span className="help-block text-3 mb-1">
            You are using the multi-file uploader.
          </span>
          <span className="help-block text-3">
            Maximum upload file size: 8 MB.
          </span>
        </Col> */}
        <Col>
          <button onClick={(e) => filesUpload(e)} className="btn btn-info">
            Upload
          </button>
        </Col>
      </Row>
    </>
  );
};

export default SliderAdd;
