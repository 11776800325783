import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useAapContext } from "../../context/AppContext";

const SignIn = () => {
  const navigate = useNavigate();
  const { isLogin, AuthCheckerHandler , isError , errorMessage} = useAapContext();

  const [userName, setUserName] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    document.querySelector("body").classList.add("loaded");
  }, []);

  useEffect(() => {
    if (isLogin === true) {
      window.location.href = "/";
    }
    if(isError === true){
      alert(errorMessage);
    }
  });

  const signIn = (e) => {
    e.preventDefault();

    if (userName === null) {
      alert("Please enter user name");
      return false;
    }
    if (password === null) {
      alert("Please enter password");
      return false;
    }

    var json = {
      user_name: userName,
      password: password,
      secret_key: process.env.REACT_APP_KEY,
    };
    AuthCheckerHandler(json);
  };

  return (
    <section className="body-sign">
      <div className="center-sign">
        <Card className="card-sign">
          <Card.Body>
            <h2 className="sign-title">Sign In</h2>
            <Form onSubmit={signIn}>
              <Form.Group className="form-custom-group">
                <Form.Label>
                  Email Address <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  required
                  autoFocus
                  onChange={(txt) => setUserName(txt.target.value)}
                />
              </Form.Group>

              <Form.Group className="form-custom-group">
                <Form.Label className="float-left">
                  Password <span className="required">*</span>
                </Form.Label>
                <Form.Control
                  type="password"
                  required
                  onChange={(txt) => setPassword(txt.target.value)}
                />
              </Form.Group>
              <Button type="submit" className="btn-login" variant="" block>
                Sign In
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </section>
  );
};

export default SignIn;
