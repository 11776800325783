import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, Table, ProgressBar } from "react-bootstrap";
import Chart from "react-apexcharts";

import Breadcrumb from "../common/breadcrumb";
import { useEffect } from "react";
import { useAapContext } from "../../context/AppContext";

const options = {
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
  },
  dataLabels: {
    enabled: false,
  },
};

const data = [
  {
    name: "Gross Revenue",
    //data: [65, 96, 75, 90, 55, 75, 85, 98, 46, 42, 81, 34],
    data: [],
  },
  {
    name: "Net Revenue",
    // data: [56, 76, 65, 80, 55, 15, 79, 78, 35, 35, 72, 22],
    data: [],
  },
];

const Dashboard = () => {
  const { isLogin, isLoggedin, checkLogin, userProfile, total_product , total_order_count } =
    useAapContext();

  useEffect(() => {
    checkLogin();
  });
  return (
    <>
      <Breadcrumb current="Dashboard" />

      <Row>
        <Col xl={5} xxl={4}>
          <Row>
            <Col className="col-12">
              <Card>
                <Card.Body className="p-0">
                  <div className="widget-user-info">
                    <div className="widget-user-info-header">
                      <h2 className="font-weight-bold text-color-dark text-5">
                        Hello, {userProfile.name}
                      </h2>
                      <p className="mb-0">Administrator</p>

                      {/* <div className="widget-user-acrostic bg-primary">
                        <span className="font-weight-bold">Me</span>
                      </div> */}
                    </div>
                    <div className="widget-user-info-body">
                      <Row>
                        {/* <Col className="col-auto">
                          <strong className="text-color-dark text-5">
                            $19,876.02
                          </strong>
                          <h3 className="text-4-1">User Balance</h3>
                        </Col> */}
                        <Col className="col-auto">
                          <strong className="text-color-dark text-5">
                            {total_product}
                          </strong>
                          <h3 className="text-4-1">Products</h3>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <Button
                            as={Link}
                            className="border font-weight-semibold text-color-dark text-3 mt-4"
                            variant="light"
                            size="xl"
                            to={`${process.env.PUBLIC_URL}/pages/user-profile`}
                          >
                            View Profile
                          </Button>
                        </Col>
                      </Row> */}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6} xl={12} className="pb-2 pb-lg-0 mb-4 mb-lg-0">
              <Card className="card-modern">
                <Card.Body className="py-4">
                  <Row className="align-items-center">
                    <Col sm={4} className="col-6">
                      <h3 className="text-4-1 my-0">Total Orders</h3>
                      <strong className="text-6 text-color-dark">{total_order_count}</strong>
                    </Col>
                    <Col
                      sm={4}
                      className="col-6 border border-top-0 border-right-0 border-bottom-0 border-color-light-grey py-3"
                    >
                      <h3 className="text-4-1 text-color-success line-height-2 my-0">
                        Orders{" "}
                        <strong className="ws-nowrap">
                          UP <i className="fas fa-long-arrow-alt-up"></i>
                        </strong>
                      </h3>
                      <span>This month</span>
                    </Col>
                    <Col
                      sm={4}
                      className="text-center text-sm-right mt-4 mt-sm-0"
                    >
                      <i className="bx bx-cart-alt icon icon-inline icon-xl bg-primary rounded-circle text-color-light"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={6} xl={12} className="pt-xl-2 mt-xl-4">
              <Card className="card-modern">
                <Card.Body className="py-4">
                  <Row className="align-items-center">
                    <Col sm={4} className="col-6">
                      <h3 className="text-4-1 my-0">Average Price</h3>
                      <strong className="text-6 text-color-dark">0</strong>
                    </Col>
                    <Col
                      sm={4}
                      className="col-6 border border-top-0 border-right-0 border-bottom-0 border-color-light-grey py-3"
                    >
                      <h3 className="text-4-1 text-color-danger line-height-2 my-0">
                        Price{" "}
                        <strong className="ws-nowrap">
                          DOWN <i className="fas fa-long-arrow-alt-down"></i>
                        </strong>
                      </h3>
                      <span>This month</span>
                    </Col>
                    <Col
                      sm={4}
                      className="text-center text-sm-right mt-4 mt-sm-0"
                    >
                      <i className="bx bx-purchase-tag-alt icon icon-inline icon-xl bg-primary rounded-circle text-color-light pr-0"></i>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xl={7} xxl={8} className="pt-2 pt-xl-0 mt-4 mt-xl-0">
          <Row className="h-100">
            <Col>
              <Card className="card-modern h-100">
                <Card.Header>
                  <Card.Title>Revenue</Card.Title>
                </Card.Header>
                <Card.Body className="h-100 pt-2">
                  <Row>
                    <Col className="col-auto">
                      <strong className="text-color-dark text-6">0</strong>
                      <h3 className="text-4 mt-0 mb-2">This Month</h3>
                    </Col>
                    <Col className="col-auto">
                      <strong className="text-color-dark text-6">0</strong>
                      <h3 className="text-4 mt-0 mb-2">Last Month</h3>
                    </Col>
                    <Col className="col-auto">
                      <strong className="text-color-dark text-6">0</strong>
                      <h3 className="text-4 mt-0 mb-2">Total Profit</h3>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="px-0">
                      <Chart
                        options={options}
                        series={data}
                        type="bar"
                        height={400}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={6} xl={5} xxl={4}>
          <div className="d-flex flex-column h-100">
            <Card className="card-modern">
              <Card.Body className="py-4">
                <Row className="align-items-center">
                  <Col sm={4} className="col-6">
                    <h3 className="text-4-1 my-0">Total Customers</h3>
                    <strong className="text-6 text-color-dark">0</strong>
                  </Col>
                  <Col
                    sm={4}
                    className="col-6 border border-top-0 border-right-0 border-bottom-0 border-color-light-grey py-3"
                  >
                    <h3 className="text-4-1 text-color-success line-height-2 my-0">
                      Customers{" "}
                      <strong className="ws-nowrap">
                        UP <i className="fas fa-long-arrow-alt-up"></i>
                      </strong>
                    </h3>
                    <span>This month</span>
                  </Col>
                  <Col
                    sm={4}
                    className="text-center text-sm-right mt-4 mt-sm-0"
                  >
                    <i className="bx bx-user icon icon-inline icon-xl bg-primary rounded-circle text-color-light p-0"></i>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="card-modern">
            <Card.Header>
              <Card.Title>Recent Orders</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="datatables-header-footer-wrapper">
                <Table
                  className="table-ecommerce-simple mb-0"
                  responsive="lg"
                  striped
                  style={{ minWidth: "600px" }}
                >
                  <thead>
                    <tr>
                      <th width="100">Order</th>
                      <th width="30%">Customer &amp; Guests</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th className="text-lg-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Link
                          to={`${process.env.PUBLIC_URL}/ecommerce/orders/3`}
                        >
                          <strong>#3</strong>
                        </Link>
                      </td>
                      <td>
                        <Link to={`${process.env.PUBLIC_URL}/users/4`}>
                          Joe Anita
                        </Link>
                      </td>
                      <td className="ws-nowrap">Mar 23, 2021</td>
                      <td>
                        <span className="ecommerce-status on-hold">
                          On Hold
                        </span>
                      </td>
                      <td className="text-lg-right">$5,680.75</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Dashboard;
