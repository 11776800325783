import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Col, Card, Form, Button, Collapse } from "react-bootstrap";
import SlideToggle from "react-slide-toggle";
import Select from "react-select";
import { toast } from "react-toastify";

import Breadcrumb from "../../common/breadcrumb";
import DeleteConfirmModal from "../../features/modals/delete-confirm-modal";
import Loader from "../../features/loader";
import PNotify from "../../features/elements/p-notify";

import { getUser, getVendor } from "../../../api";
import { COUNTRIES, STATES } from "../../../utils/data/constant";
import axios from "axios";

const VendorAdd = (props) => {
  const API_URL = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();
  
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [resetPwd, setResetPwd] = useState(false);
  const [name, setName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp_no, setWhatsApp] = useState("");
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");

  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [diffShipping, setDiffShipping] = useState(true);
  const [shipping, setShipping] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  function saveUser(e) {
    e.preventDefault();
    const userData = {
      name: name,
      last_name: last_name,
      email: email,
      whatsapp_no: whatsapp_no,
      phone: phone,
      address: address,
      description: description,
    };

    axios
      .post(API_URL + "vendor_add", userData)
      .then((response) => {
        let results = response.data;
        if (typeof results.errors !== "undefined") {
          setError(results.errors);
        }

        if (results.status === 200) {
          toast(
            <PNotify
              title="Success"
              icon="fas fa-check"
              text="User saved successfully."
            />,
            {
              containerId: "default",
              className: "notification-success",
            }
          );
          navigate("/vendor");
        }
      })
      .catch((error) => console.log(error));
  }

  function deleteUser(e) {
    e.preventDefault();
    setOpenModal(true);
  }

  function deleteConfirm(result) {
    setOpenModal(false);
    result && props.history.push(`${process.env.PUBLIC_URL}/users`);
  }

  function userChange(key, value) {
    let temp = { ...user };
    temp[key] = value;
    setUser(temp);
  }

  function shippingChange(key, value) {
    let temp = { ...shipping };
    temp[key] = value;
    setShipping(temp);
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Breadcrumb
            current="Edit User"
            paths={[
              {
                name: "Home",
                url: "/",
              },
              {
                name: "Vendor",
                url: `/vendor`,
              },
            ]}
          />

          <Form
            className="ecommerce-form"
            action="#"
            method="post"
            onSubmit={saveUser}
          >
            <Row>
              <Col>
                <Card className="card-modern card-big-info">
                  <Card.Body>
                    <Row>
                      <Col lg="2-5" xl="1-5">
                        <i className="card-big-info-icon bx bx-user-circle"></i>
                        <h2 className="card-big-info-title">Account Info</h2>
                        <p className="card-big-info-desc">
                          Add here the user account info.
                        </p>
                      </Col>
                      <Col lg="3-5" xl="4-5">
                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            First Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="text"
                              className="form-control-modern"
                              value={name ? name : ""}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Last Name
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="text"
                              className="form-control-modern"
                              value={last_name ? last_name : ""}
                              onChange={(e) => setLastName(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Email
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="email"
                              className="form-control-modern"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Phone
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="number"
                              className="form-control-modern"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            WhatsApp
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              type="number"
                              className="form-control-modern"
                              value={whatsapp_no}
                              onChange={(e) => setWhatsApp(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>

                        {/* <Form.Group as={Row} className="align-items-center">
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mb-lg-0"
                          >
                            Password Reset
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Check
                              className="checkbox-default"
                              custom
                              id="checkboxExample1"
                              checked={resetPwd}
                              onChange={(e) => {
                                setResetPwd(e.target.checked);
                              }}
                              label="Do you want to reset password?"
                            />
                          </Col>
                        </Form.Group> */}

                        <Collapse in={resetPwd}>
                          <Form.Group
                            style={{ margin: "0 -15px" }}
                            className="py-0 mb-0"
                          >
                            <Form.Group
                              as={Row}
                              className="align-items-center mx-0 pt-3"
                            >
                              <Col
                                as={Form.Label}
                                lg={5}
                                xl={3}
                                className="control-label text-lg-right mb-lg-0"
                              >
                                Password
                              </Col>
                              <Col lg={7} xl={6}>
                                <Form.Control
                                  type="password"
                                  className="form-control-modern"
                                  value={pwd}
                                  onChange={(e) => setPwd(e.target.value)}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="align-items-center mx-0 mb-3"
                            >
                              <Col
                                as={Form.Label}
                                lg={5}
                                xl={3}
                                className="control-label text-lg-right mb-lg-0"
                              >
                                Password Confirm
                              </Col>
                              <Col lg={7} xl={6}>
                                <Form.Control
                                  type="password"
                                  className="form-control-modern"
                                  value={pwdConfirm}
                                  onChange={(e) =>
                                    setPwdConfirm(e.target.value)
                                  }
                                />
                              </Col>
                            </Form.Group>
                          </Form.Group>
                        </Collapse>
                        <Form.Group as={Row}>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mt-lg-2 mb-0"
                          >
                            Address
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              as="textarea"
                              rows="5"
                              className="form-control-modern"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                          <Col
                            as={Form.Label}
                            lg={5}
                            xl={3}
                            className="control-label text-lg-right mt-lg-2 mb-0"
                          >
                            Description
                          </Col>
                          <Col lg={7} xl={6}>
                            <Form.Control
                              as="textarea"
                              rows="5"
                              className="form-control-modern"
                              value={description ? description : ""}
                              onChange={(e) => setDescription(e.target.value)}
                              required
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="action-buttons">
              <Col md="auto" className="col-6">
                <Button
                  type="submit"
                  className="btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1"
                  variant="primary"
                >
                  <i className="bx bx-save text-4 mr-2"></i> Save User
                </Button>
              </Col>
              <Col md="auto" className="col-6 px-md-0 mt-0">
                <Button
                  as={Link}
                  to={`/vendor`}
                  className="btn-px-4 py-3 border font-weight-semibold text-color-dark line-height-1 d-flex h-100 align-items-center"
                  variant="light"
                >
                  Back
                </Button>
              </Col>
              <Col md="auto" className="col-6 ml-md-auto mt-3 mt-md-0">
                <Button
                  href="#delete"
                  className="btn-px-4 py-3 d-flex align-items-center font-weight-semibold line-height-1"
                  variant="danger"
                  onClick={deleteUser}
                >
                  <i className="bx bx-trash text-4 mr-2"></i> Delete User
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}

      <DeleteConfirmModal isOpen={openModal} onClose={deleteConfirm} />
    </>
  );
};

export default VendorAdd;
