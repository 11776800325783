import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Button, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

import Breadcrumb from "../../common/breadcrumb";
import PtTable from "../../features/elements/table";
import PtSwitch from "../../features/elements/switch";
import PNotify from "../../features/elements/p-notify";

import { getVendors } from "../../../api";
import axios from "axios";

export default function VendorList() {
  const API_URL = process.env.REACT_APP_API_URL;
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [ajax, setAjax] = useState({
    data: [],
    total: 0,
  });

  // Variables
  const [search, setSearch] = useState("");

  const [selectAll, setSelectAll] = useState(false);
  const [tableRef, setTableRef] = useState(null);
  const [selected, setSelected] = useState([]);
  const [bulk, setBulk] = useState("");
  const [error, setError] = useState("");

  // Columns
  const columns = [
    {
      Header: "Name",
      id: "name",
      sortable: true,
      accessor: (d) =>
        (d.name ? d.name : "") + " " + (d.last_name ? d.last_name : ""),
      width: 150,
      Cell: (row) => (
        <Link
          to={`${process.env.PUBLIC_URL}/users/${row.original.id}`}
          className="ml-1"
        >
          <strong>{row.value}</strong>
        </Link>
      ),
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "WhatsApp",
      accessor: "whatsapp_no",
    },
    {
      Header: "Status",
      id: "status",
      // accessor: "whatsapp_no",
      accessor: (d) => {
        return { status: d.is_active, id: d.id };
      },
      Cell: (row) => (
        <div className="switch switch-sm switch-primary">
          <PtSwitch
            on={row.value.status}
            onChange={(e) => statusChanged(e, row.value.id)}
          />
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "id",
      className: "actions",
      headerClassName: "justify-content-center",
      width: 100,
      Cell: (row) => (
        <>
          <Link
            to={`/vendor/edit/${row.value}`}
            className="on-default edit-row mr-2"
          >
            <i className="fas fa-pencil-alt"></i>
          </Link>
          <a
            href="#del"
            className="on-default remove-row"
            onClick={(e) => deleteRow(e, row.index, row.value)}
          >
            <i className="far fa-trash-alt"></i>
          </a>
        </>
      ),
    },
  ];

  useEffect(() => {
    setSelected(
      selected.map((item) => {
        return {
          ...item,
          selected: selectAll,
        };
      })
    );
  }, [selectAll]);

  function isSelected(key) {
    return selected.find((item) => item.id === key && item.selected);
  }

  function onSelectChange(e, value, row) {
    setSelected(
      selected.map((item) => {
        if (item.id === row.id) {
          return {
            ...item,
            selected: !item.selected,
          };
        }
        return item;
      })
    );
  }

  function deleteRow(e, index, id) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this data?")) {
      setLoading(true);
      return axios
        .delete(API_URL + "vendor_delete/" + id)
        .then((response) => {
          if (response.status === 200) {
            setAjax({
              ...ajax,
              data: ajax.data.filter((item, id) => id !== index),
            });
            toast(
              <PNotify
                title="Success"
                icon="fas fa-check"
                text="Vendor delete successfully."
              />,
              {
                containerId: "default",
                className: "notification-success",
              }
            );

            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function bulkAction(e) {
    e.preventDefault();
    if (!bulk) {
      return toast(
        <PNotify
          title="Warning"
          icon="fas fa-exclamation"
          text="Please choose one of actions."
        />,
        {
          containerId: "default",
          className: "notification-warning",
        }
      );
    }

    if (bulk === "delete") {
      if (!selected.find((item) => item.selected)) {
        return toast(
          <PNotify
            title="Warning"
            icon="fas fa-exclamation"
            text="Choose at least one item."
          />,
          {
            containerId: "default",
            className: "notification-warning",
          }
        );
      }

      var ids = selected.filter((item) => item.selected);
      ids = ids.map((item) => item.id);

      return axios
        .put(API_URL + "delete_multiple_vendor", { ids: ids })
        .then((response) => {
          if (response.status === 200) {
            setAjax({
              ...ajax,
              data: ajax.data.filter((media) =>
                selected.find((item) => item.id === media.id && !item.selected)
              ),
            });
            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }

  function searchVendors(e) {
    e.preventDefault();
    tableRef.current.wrappedInstance.filterColumn({ id: "name" }, search);
  }

  function fetchData(state) {
    setLoading(true);
    getVendors(
      state.page * state.pageSize,
      (state.page + 1) * state.pageSize,
      [...state.filtered, { id: "role_id", value: 3 }],
      state.sorted
    ).then((results) => {
      setLoading(false);
      setAjax({
        data: results.data,
        total:
          parseInt(results.total / state.pageSize) +
          !!(results.total % state.pageSize),
      });
      setSelected(
        results.data.map((media) => {
          return {
            id: media.id,
            selected: false,
          };
        })
      );
      setSelectAll(false);
    });
  }

  function statusChanged(e, id) {
    var user = {
      is_active: e === true ? 1 : 0,
    };

    axios
      .put(API_URL + "vendor_status_change/" + id, user)
      .then((response) => {
        let results = response.data;
        if (typeof results.errors !== "undefined") {
          setError(results.errors);
        }

        if (results.status === 200) {
          toast(
            <PNotify
              title="Success"
              icon="fas fa-check"
              text="Vendor Status Changed."
            />,
            {
              containerId: "default",
              className: "notification-success",
            }
          );
        }
      })
      .catch((error) => console.log(error));
  }

  return (
    <>
      <Breadcrumb
        current="Vendors"
        paths={[
          {
            name: "Home",
            url: "/",
          },
        ]}
      />

      <Row>
        <Col>
          <Form method="get" onSubmit={searchVendors}>
            <Card className="card-modern">
              <Card.Body>
                <div className="datatables-header-footer-wrapper">
                  <div className="datatable-header">
                    <Row className="align-items-center mb-4">
                      <Col lg="auto" className="mb-3 mb-lg-0">
                        <Button
                          as={Link}
                          to={`${process.env.PUBLIC_URL}/vendor/create`}
                          className="font-weight-semibold"
                          variant="primary"
                          size="md"
                        >
                          + Add Vendor
                        </Button>
                      </Col>
                      <Col lg="auto" className="ml-auto">
                        <div className="search search-style-1 mx-lg-auto w-auto">
                          <InputGroup>
                            <Form.Control
                              type="text"
                              className="search-term"
                              name="search-term"
                              id="search-term"
                              placeholder="Search"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <InputGroup.Append>
                              <Button variant="default" type="submit">
                                <i className="bx bx-search"></i>
                              </Button>
                            </InputGroup.Append>
                          </InputGroup>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <PtTable
                    className="table table-ecommerce-simple -striped mb-0"
                    data={ajax.data}
                    loading={loading}
                    columns={columns}
                    pages={ajax.total}
                    pageSize={12}
                    manual
                    onFetchData={fetchData}
                    selectAll={selectAll}
                    toggleAll={() => setSelectAll(!selectAll)}
                    isSelected={(key) => isSelected(key)}
                    toggleSelection={onSelectChange}
                    onChangeRef={(ref) => setTableRef(ref)}
                  />

                  <div className="datatable-footer">
                    <Row className="align-items-center justify-content-between mt-3">
                      <Col md="auto" className="mb-3 mb-lg-0">
                        <div className="d-flex">
                          <Form.Control
                            as="select"
                            className="select-style-1 bulk-action w-auto mr-3"
                            value={bulk}
                            onChange={(e) => setBulk(e.target.value)}
                            style={{ minWidth: "120px" }}
                          >
                            <option value="">Bulk Actions</option>
                            <option value="delete">Delete</option>
                          </Form.Control>
                          <Button
                            href="#bulk-action"
                            className="bulk-action-apply border font-weight-semibold text-color-dark text-3"
                            variant="light"
                            onClick={bulkAction}
                          >
                            Apply
                          </Button>
                        </div>
                      </Col>
                      <Col lg="auto" className="mb-3 mb-lg-0">
                        <div className="pagination-wrapper d-flex justify-content-lg-end"></div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Form>
        </Col>
      </Row>
    </>
  );
}
